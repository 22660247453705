import React, {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import {useNavigate, useParams} from "react-router-dom";
import { RaeditorContainer, SidePanelWrap, WorkspaceWrap } from 'raeditor';
import { ZoomButtons } from 'raeditor/toolbar/zoom-buttons';
import { SidePanel, DEFAULT_SECTIONS, SizeSection } from 'raeditor/side-panel';
import { Workspace } from 'raeditor/canvas/workspace';
import { Tooltip } from './sections/tooltip';
import { PagesTimeline } from 'raeditor/pages-timeline';
import {
  setTranslations
} from 'raeditor/config';

import { loadFile } from './file';
import { StableDiffusionSection } from './sections/stable-diffusion-section';
import { IconsSection } from './sections/icons-section';
import { ShapesSection } from './sections/shapes-section';
import { MyDesignsSection } from './sections/my-designs-section';
import { DefaultTemplatesSection } from "./sections/default-templates-section";
import { PhotosSection } from "./sections/photos-section";
import { LayersSection } from "./sections/layers-panel";
import { useProject } from './project';
import fr from './translations/fr';
import en from './translations/en';
import id from './translations/id';
import ru from './translations/ru';
import ptBr from './translations/pt-br';
import Topbar from './topbar/topbar';
import Toolbar from "./sections/toolbar";
import {RatingStarSection} from "./sections/ratingStarSection";
import {AiImageGenrate} from "./sections/AiImageGenrate";
import {ImageClipSection} from "./sections/image-clip-panel";
import { decryptId } from './Encrypt/encode';

let DEFAULTSECTIONS = DEFAULT_SECTIONS.filter(section => section.name !== 'templates');
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(section => section.name !== 'icons');
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(section => section.name !== 'elements');
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(section => section.name !== 'size');
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(section => section.name !== 'photos');
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(section => section.name !== 'layers');
DEFAULTSECTIONS.unshift(ShapesSection);
DEFAULTSECTIONS.push(LayersSection);
DEFAULTSECTIONS.push(RatingStarSection);
DEFAULTSECTIONS.push(ImageClipSection);
// DEFAULTSECTIONS.push(AiImageGenrate);
DEFAULTSECTIONS.push(StableDiffusionSection);


DEFAULTSECTIONS.unshift(IconsSection);
DEFAULTSECTIONS.unshift(PhotosSection);
DEFAULTSECTIONS.unshift(MyDesignsSection);
DEFAULTSECTIONS.unshift(DefaultTemplatesSection);
DEFAULTSECTIONS.unshift(SizeSection);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight - 64);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const Editor = observer(({ store }) => {
  const navigate = useNavigate();
  const { projectId, templateId } = useParams();
  const project = useProject();
  const height = useHeight();

  // useEffect(() => {
  //   // define the function that will run upon back button press
  //   const handleBackButtonEvent = (ev) => {
  //     ev.preventDefault();

  //     // here, you can implement the navigation action you want,
  //     // for instance navigating to a default page:
  //     console.log('project id',projectId)
  //     navigate(`/projects/${projectId}`);
  //   };
  //   // attach the listener for back button event
  //   window.addEventListener('popstate', handleBackButtonEvent);

  //   // clean up the listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handleBackButtonEvent);
  //   }

  // }, []);

  React.useEffect(() => {
    if (project.language.startsWith('fr')) {
      setTranslations(fr);
    } else if (project.language.startsWith('id')) {
      setTranslations(id);
    } else if (project.language.startsWith('ru')) {
      setTranslations(ru);
    } else if (project.language.startsWith('pt')) {
      setTranslations(ptBr);
    } else {
      setTranslations(en);
    }
  }, [project.language]);


  //   useEffect(() => {
  //   // Handle back button press
  //   const handleBackButtonEvent = (ev) => {
  //     ev.preventDefault();

  //     // Navigate to a specific path instead of going back
  //     console.log('project id', projectId);
  //     navigate(`/projects/${projectId}`);
  //   };

  //   // Attach the listener to the popstate event
  //   window.addEventListener('popstate', handleBackButtonEvent);

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handleBackButtonEvent);
  //   };
  // }, [navigate, projectId]);
  
  React.useEffect(() => {
    project.clear();
    project.name = 'Untitled Design';
    project.projectId = projectId;
    let defaultTemplateId = localStorage.getItem('templateId');
    let customSize = localStorage.getItem('customSize');
    if(templateId){
      // console.log(templateId,"temp3lateId3")

      // if (templateId.length > 10) {
      //   project.firstLoad(decryptId(templateId), projectId);
      // } else {
      project.firstLoad(templateId, projectId);
        
      // }
    } else if (defaultTemplateId){
      project.defaultLoadById(defaultTemplateId);
      localStorage.removeItem('templateId');
    } else if(customSize){
      customSize = customSize.split('x')
      project.store.setSize(parseInt(customSize[0]), parseInt(customSize[1]));
      localStorage.removeItem('customSize');
    }
  }, []);

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  return (
    <div className="bp5-dark"
      style={{
        width: '99vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop}
    >
      <Topbar store={store} />
      <div style={{ height: 'calc(100% - 50px)' }}>
        <RaeditorContainer className="raeditor-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULTSECTIONS} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar
              store={store}
            />
             {/* Add this button */}
            <Workspace store={store} components={{ Tooltip }} />
            <ZoomButtons store={store} />
            <PagesTimeline store={store} />
          </WorkspaceWrap>
        </RaeditorContainer>
      </div>
    </div>
  );
});

export default Editor;
