import axios from 'axios';
import Cookies from 'js-cookie';
import {decryptId, encryptId} from "./Encrypt/encode";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;

// const getAuthToken = () => localStorage.getItem('token');
const getAuthToken = () => Cookies.get('token');
// const getAuthToken = () => sessionStorage.getItem('token');


const apiRequest = async ({url, method, headers = {}, params = {}, data = null}) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error("Authorization token is missing");
    }

    const authHeaders = { 'Authorization': `Bearer ${token}` };
    const request = {
        method,
        url: `${API_BASE_URL}${url}`,
        headers: { ...authHeaders, ...headers },
        params,
    };

    if (data) {
        request.data = data;
    }

    const response = await axios(request);
    return response.data;
};

export const getPhotos = async (query, page) => {
    return apiRequest({ url: '/photos/unsplash', method: 'GET', params: { query, page, per_page: 50 } });
};

export const listDefaultTemplates = async (page = 1, search = '', width = '', height = '') => {
    const params = { page, search, ...(width && height ? { width, height } : {}) };
    return apiRequest({ url: '/default-templates', method: 'GET', params: params });
};

export const getDefaultTemplateById = async ({ id }) => {
    return apiRequest({ url: `/default-templates/${id}`, method: 'GET' });
};

export const saveDesign = async ({ storeJSON, preview, name, id, projectId }) => {
    const formData = new FormData();

    formData.append('template_name', name);
    formData.append('projectId', projectId);
    formData.append('template_data', JSON.stringify(storeJSON));
    formData.append('thumbnail', preview);

    if (id) {
        formData.append('template_id', id);
    }

    return apiRequest({
        url: '/templates',
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const listDesigns = async () => {
    return apiRequest({ url: '/templates', method: 'GET' });                
};

export const deleteDesign = async ({ id }) => {
    return apiRequest({ url: `/templates/${id}`, method: 'DELETE' });
};

export const loadById = async ({ id }) => {
    console.log('good',id);

    // const loadId = encryptId(id);
    // console.log(loadId,'danger')
    return apiRequest({ url: `/templates/${id}`, method: 'GET' });
};

export const loadByUUID = async ({ id }) => {
    return apiRequest({ url: `/templates/uuid/${id}`, method: 'GET' });
};

export const getPreview = async ({ id }) => {
    return apiRequest({ url: `/templates/${id}/preview`, method: 'GET', responseType: 'blob' });
};

export const listImages = async () => {
    return apiRequest({ url: '/images', method: 'GET' });
};

export const getImageSrc = async ({ id }) => {
    return apiRequest({ url: `/images/${id}`, method: 'GET', responseType: 'blob' });
};

// export const uploadImage = async ({ file }) => {
//     const formData = new FormData();
//     formData.append('file', file);

//     return apiRequest({
//         url: '/photos/upload',
//         method: 'POST',
//         data: formData,
//         headers: { 'Content-Type': 'multipart/form-data' }
//     });
// };
export const uploadImage = async ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await apiRequest({
            url: '/photos/upload',
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        
        // Return both the response data and status
        return {
            data: response,
            status: 'success'
        };
    } catch (error) {
        // If there's an error, return the error message and status
        return {
            data: error.response ? error.response.data : 'An error occurred',
            status: 'error'
        };
    }
};

export const deleteImage = async ({ id }) => {
    return apiRequest({ url: `/images/${id}`, method: 'DELETE' });
};
