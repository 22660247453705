import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Card,
    Menu,
    MenuItem,
    Position,
    Spinner,
    Popover,
} from '@blueprintjs/core';

import { SectionTab } from 'raeditor/side-panel';
import FaFolder from '@meronex/icons/fa/FaFolder';
import { useProject } from '../project';
import * as api from '../api';

const DesignCard = observer(({ design, store, onDelete }) => {
    const [loading, setLoading] = React.useState(false);
    const handleSelect = async () => {
        setLoading(true);
        await window.project.loadById(design.id, design.project_id, design.template_uuid);
        setLoading(false);
    };

    return (
        <Card
            style={{ margin: '3px', padding: '5px', position: 'relative', borderRadius: '5px' }}
            interactive
            onClick={handleSelect}
        >
            <img src={design.thumbnail} style={{ width: '100%', borderRadius: '5px' }} alt="Design preview" />
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    padding: '3px',
                }}
            >
                {design.template_name}
            </div>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Spinner />
                </div>
            )}
            <div
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Popover
                    content={
                        <Menu>
                            <MenuItem
                                icon="document-open"
                                text="Open"
                                onClick={handleSelect}
                            />
                            <MenuItem
                                icon="trash"
                                text="Delete"
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to delete it?')) {
                                        onDelete({ id: design.id });
                                    }
                                }}
                            />
                        </Menu>
                    }
                    position={Position.BOTTOM}
                >
                    <Button icon="more" />
                </Popover>
            </div>
        </Card>
    );
});

export const MyDesignsPanel = observer(({ store }) => {
    const project = useProject();
    const [designsLoading, setDesignsLoading] = React.useState(false);
    const [designs, setDesigns] = React.useState([]);

    const loadDesigns = async () => {
        setDesignsLoading(true);
        const list = await api.listDesigns();
        setDesigns(list);
        setDesignsLoading(false);
    };

    const handleProjectDelete = async ({ id }) => {
        setDesigns(designs.filter((design) => design.id !== id));
        await api.deleteDesign({ id });
    };

    React.useEffect(() => {
        loadDesigns();
    }, [project.cloudEnabled, project.designsLength]);

    const half1 = [];
    const half2 = [];

    designs.forEach((design, index) => {
        if (index % 2 === 0) {
            half1.push(design);
        } else {
            half2.push(design);
        }
    });

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Button
                fill
                intent="primary"
                onClick={project.createNewDesign}
            >
                Create new design
            </Button>
            {!designsLoading && !designs.length && (
                <div style={{ paddingTop: '20px', textAlign: 'center', opacity: 0.6 }}>
                    You have no saved designs yet...
                </div>
            )}
            {designsLoading && (
                <div style={{ padding: '30px' }}>
                    <Spinner />
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    paddingTop: '5px',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <div style={{ width: '50%' }}>
                    {half1.map((design) => (
                        <DesignCard
                            design={design}
                            key={design.id}
                            store={store}
                            onDelete={handleProjectDelete}
                        />
                    ))}
                </div>
                <div style={{ width: '50%' }}>
                    {half2.map((design) => (
                        <DesignCard
                            design={design}
                            key={design.id}
                            store={store}
                            onDelete={handleProjectDelete}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

// Define the new custom section
export const MyDesignsSection = {
    name: 'my-designs',
    Tab: (props) => (
        <SectionTab name="My Designs" {...props}>
            <FaFolder className='w-5 h-5' />
        </SectionTab>
    ),
    // We need observer to update component automatically on any store changes
    Panel: MyDesignsPanel,
};
