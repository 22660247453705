import React, { useEffect, useMemo } from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { Cross } from '@blueprintjs/icons';
import { observer } from 'mobx-react-lite';
import { ImagesGrid } from 'raeditor/side-panel/images-grid';
import { svgToURL } from 'raeditor/utils/svg';
import { figureToSvg, TYPES as FIGURE_TYPES } from 'raeditor/utils/figure-to-svg';
import styled from 'raeditor/utils/styled';
import { t } from 'raeditor/utils/l10n';
import {SectionTab} from "raeditor/side-panel";
import {BsFillGrid1X2Fill} from "react-icons/bs";
import {DefaultTemplatesPanel} from "./default-templates-section";

const FIGURE_DEFAULTS = {
    width: 300,
    height: 300,
    fill: 'lightgray',
    stroke: '#0c0c0c',
    strokeWidth: 0,
    url: '',
};

const subTypes = Object.keys(FIGURE_TYPES);
const DEFAULTS = [FIGURE_DEFAULTS];
const FIGURES = [];

subTypes.forEach((subType) => {
    DEFAULTS.forEach((defaults) => {
        FIGURES.push(Object.assign({ subType }, defaults));
    });
});

FIGURES.forEach((figure) => {
    figure.url = svgToURL(figureToSvg(figure));
});

const BasicShapesContainer = styled('div')`
    height: 220px;
`;

export const ImageClipPanel = observer(({ store }) => {
    const rowsCount = Math.ceil(FIGURES.length / 4) || 1;
    const generateSelectedElementsIds = () => store.selectedElements.map((element) => element.id).join(',');
    const initialSelectedElementsIds = useMemo(generateSelectedElementsIds, []);
    const currentSelectedElementsIds = generateSelectedElementsIds();

    useEffect(() => {
        if (initialSelectedElementsIds !== currentSelectedElementsIds) {
            store.openSidePanel('photos');
        }
    }, [initialSelectedElementsIds, currentSelectedElementsIds, store]);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, lineHeight: '30px' }}>{t('sidePanel.clipImage')}</h3>
                <Button minimal icon={<Cross />} onClick={() => store.openSidePanel('photos')} />
            </div>
            <BasicShapesContainer style={{ height: `${110 * rowsCount}px` }}>
                <ImagesGrid
                    shadowEnabled={false}
                    rowsNumber={4}
                    images={FIGURES}
                    getPreview={(figure) => figure.url}
                    isLoading={false}
                    itemHeight={100}
                    onSelect={async (selectedFigure, index, element) => {
                        element = element || store.selectedElements[0];
                        if (element && element.type === 'image' && element.contentEditable) {
                            element.set({ clipSrc: selectedFigure.url });
                        }
                    }}
                />
            </BasicShapesContainer>
        </div>
    );
});

export const ImageClipSection = {
    name: 'image-clip',
    Tab: (props) => null,
    Panel: ImageClipPanel,
};