import React, { useState, useEffect} from "react";
import {
  Label,
  Modal,
  TextInput,
} from "flowbite-react";
import { Tooltip } from "flowbite-react";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  addProject,
  getProject,
  deleteProject,
  editProject,
  uploadImage,
  cloneProject
} from "../APIservice/apiService";
import { toast } from "react-toastify";
import { nameCharLimit } from "../CharLimit";

const ProjectTab = () => {
  const navigate = useNavigate(); // Hook for navigation

  const redirectToAnotherPage = (id) => {
    // const encryptedId = encryptId(id);
    // navigate(`/projects/${encryptedId}`); // Specify the path you want to redirect to
    navigate(`/projects/${id}`);
  };

  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [projectNameError, setProjectNameError] = useState("");
  const [editId, setEditId] = useState("");
  const [editLogo, setEditLogo] = useState("");
  const [fileError, setFileError] = useState("");
  const [projects, setProjects] = useState([]);
  const [isFileValid, setIsFileValid] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [edit, setEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [projectLength, setProjectLength] = useState(null);
  const [projectname, setProjectname] = useState(true);
  const [modalPlacement, setModalPlacement] = useState("center");

  const [loading, setLoading] = useState(true); // Manage loading state

  function onCloseModal() {
    setOpenModal(false);
    setEdit(false);
    setName("");
    setFile(null);
    setPreviewImage("");
    setEditLogo("")
    setFileError("");
    setProjectNameError("");
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getProject();
      if (response) {
        setProjects(response);
        setProjectLength(response.length);
      }
      setLoading(false);
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function onOpenModel() {
    setProjectname(true);
    setOpenModal(true);
    setName("");
    setFile(null);
  }

  function handleEdit(project) {
    setProjectname(false);
    setEdit(true);
    setOpenModal(true);
    setName(project.name);
    setEditId(project.id);
    setEditLogo(project.logo);
    setFile(null);
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const handleConfirmation = (swalWithBootstrapButtons, id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to delete this project!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await deleteProject(id);
            if (response) {
              toast.success("Project Deleted Successfully");
              fetchData();
            }
          } catch (error) {
            console.error("Login failed:", error);
          }
        }
      });
  };

  const handleFileUpload = async (event) => {
    try {
      console.log(selectedFile,"SELECTED FILE")
      const response = await uploadImage(selectedFile);
      if (response) {
        setSelectedFile("");
        return response;
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };


  const handleAddProject = async (event) => {
    setProjectname(true);
    const projectNameRegex = /^(?! )[A-Za-z0-9 ]*(?<! )$/;
    let trimmedName = name.trim();
    if (trimmedName === "") {
      setProjectNameError("Please enter a project name");
    } else if (!projectNameRegex.test(trimmedName)) {
      setProjectNameError("Enter Valid Project Name");
    } else if (trimmedName.length > nameCharLimit) {
      setProjectNameError(
        `Project name cannot exceed ${nameCharLimit} characters`
      );
    } else {
      try {
        const addProjectResponse = await addProject(trimmedName);
        console.log(addProjectResponse.name, "here project");
        if (addProjectResponse.name) {
          onCloseModal();
          fetchData();
        }
        // }
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
  };

  const handleEditProject = async () => {
    setProjectname(false);
    const projectNameRegex = /^(?! )[A-Za-z0-9 ]*(?<! )$/;
    let trimmedName = name.trim();

    if (trimmedName === "") {
      setProjectNameError("Please enter a project name");
    } else if (!projectNameRegex.test(trimmedName)) {
      setProjectNameError("Enter Valid Project Name");
    } else if (trimmedName.length > nameCharLimit) {
      setProjectNameError(
        `Project name cannot exceed ${nameCharLimit} characters`
      );
    } else {
      try {
        if (previewImage) {
          const responses = await handleFileUpload();
          if (responses) {
            const response = await editProject(
              editId,
              trimmedName,
              responses.url
            );
            if (response) {
              onCloseModal();
              fetchData();
            }
          }
        } else {
          const response = await editProject(editId, trimmedName);
          if (response) {
            onCloseModal();
            fetchData();
          }
        }
      } catch (error) {
        console.error("Edit project failed:", error);
      }
    }
  };

  const handleClone = async (project) => {
    try {
      const response = await cloneProject(project.id);
      if (response.name) {
        onCloseModal();
        fetchData();
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleClone1 = (imageUrl) => {
    // Encode the image URL
    // const encodedImageUrl = encodeURIComponent(imageUrl);
    // console.log(encodedImageUrl,"Encode")
    // Navigate to the new page with the encoded image URL as a query parameter
    // navigate(`/projects/635/template/wp/${encodedImageUrl}`);
    navigate(`/projects/635/template/wp-template-i?imageUrl=${imageUrl}`);
    // :imageurl
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-[75vh]">
        <div className="mx-auto sm:px-6 lg:px-8">
          <div className="flex justify-between py-6 items-center dashboard_h2">
            <h2 className="text-2xl lg:text-4xl font-extrabold leading-tight dark:text-white">
              Your Projects
            </h2>
            <button
              onClick={onOpenModel}
              type="button"
              className="p-[10px] py-2.5 text-xs md:text-sm font-medium rounded-full inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              <svg
                className="w-5 h-5 mr-2 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 12h14m-7 7V5"
                ></path>
              </svg>
              ADD NEW PROJECT
            </button>
          </div>

          <div className="grid gap-3 mb-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-center sm:w-full  md:w-full min-w-full mt-5">
            {loading ? (
              // Skeleton Loader
              <>
                {[1, 2, 3, 4].map((_, index) => (
                  <div
                    key={index}
                    className="flex p-3 sm:p-6 rounded-lg shadow-lg bg-gray-800 dark:border-gray-700 animate-pulse"
                  >
                    <div className="flex-grow flex flex-col justify-center cursor-pointer">
                      <div className="h-6 bg-gray-700 rounded-md w-3/4 mb-2"></div>
                      <div className="h-4 bg-gray-700 rounded-md w-1/2"></div>
                    </div>
                    <div className="actions flex justify-between items-center ml-3">
                      <div className="w-6 h-6 bg-gray-700 rounded-full"></div>
                      <div className="w-6 h-6 bg-gray-700 rounded-full ml-2"></div>
                      <div className="w-6 h-6 bg-gray-700 rounded-full ml-2"></div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              projects.map((project) => (
                <div
                  className="flex p-3 sm:p-6 rounded-lg shadow-lg bg-gray-800 dark:border-gray-700 hover:bg-gray-700"
                  key={project.id}
                >
                  <div
                    onClick={() => redirectToAnotherPage(project.id)}
                    className="flex-grow flex flex-col justify-center cursor-pointer"
                  >
                    <p className=" text-sm sm:text-lg md:text-xl font-extrabold truncate text-white">
                      {/* {project.name} */}
                      {project.name.length > 25 ? `${project.name.substring(0, 25)}...` : project.name}
                    </p>
                    <p className="text-xs text-white truncate ">
                      {project.template_count} Templates
                    </p>
                  </div>
                  <div className="actions flex justify-between items-center">
                  <Tooltip content="Edit" placement="top">
                    <button
                      // onClick={handleEdit(project)}
                      onClick={() => handleEdit(project)}
                      type="button"
                      className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4 text-white"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z"></path>
                      </svg>
                    </button>
                    </Tooltip>
                    <Tooltip content="Delete" placement="top">
                    <button
                      onClick={() =>
                        handleConfirmation(swalWithBootstrapButtons, project.id)
                      }
                      type="button"
                      className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4 text-white dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                        />
                      </svg>

                      <span className="sr-only">Icon description</span>
                    </button>
                    </Tooltip>
                    <Tooltip content="Clone" placement="top">
                    <button
                      onClick={() => handleClone(project)}
                      type="button"
                      className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4 text-white dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 12.732A1.99 1.99 0 0 1 7 13H3v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2h-2a4 4 0 0 1-4-4v-2.268ZM7 11V7.054a2 2 0 0 0-1.059.644l-2.46 2.87A2 2 0 0 0 3.2 11H7Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M14 3.054V7h-3.8c.074-.154.168-.3.282-.432l2.46-2.87A2 2 0 0 1 14 3.054ZM16 3v4a2 2 0 0 1-2 2h-4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
                          clipRule="evenodd"
                        />
                      </svg>

                      <span className="sr-only">Icon description</span>
                    </button>
                    </Tooltip>

                  {/* <button
                    onClick={() => handleClone1("https://images.unsplash.com/photo-1728574111702-286dff4b18e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw5fHx8ZW58MHx8fHx8")}
                    type="button"
                    className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4 text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 12.732A1.99 1.99 0 0 1 7 13H3v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2h-2a4 4 0 0 1-4-4v-2.268ZM7 11V7.054a2 2 0 0 0-1.059.644l-2.46 2.87A2 2 0 0 0 3.2 11H7Z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M14 3.054V7h-3.8c.074-.154.168-.3.282-.432l2.46-2.87A2 2 0 0 1 14 3.054ZM16 3v4a2 2 0 0 1-2 2h-4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="sr-only">Icon description</span>
                  </button> */}
                </div>
              </div>
            ))
            )}
          </div>

          {projectLength === 0 ? (
            <div className="text-center w-full h-auto  flex justify-center   rounded-lg shadow sm:p-8">
              <h1 className="text-2xl sm:text-4xl  font-extrabold dark:text-white dark:bg-gray-800 dark:border-gray-700 py-7 px-10 rounded-lg">
                No Data Found
              </h1>
            </div>
          ) : (
            ""
          )}
          {/*  <Modal
            show={openModal}
            size="md"
            onClose={onCloseModal}
            popup
            center
            className="mt-[50%] sm:mt-0"
          >
            <Modal.Header />
            <Modal.Body className="centered">
              <div className="space-y-6 ">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  {projectname ? 'Create Project' : 'Edit Project'}
                </h3>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="name" value="Project Name " />
                  </div>
                  <TextInput
                    id="email"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                      setProjectNameError(""); // Clear the project name error when user types
                    }}
                    required
                  />
                  {projectNameError && (
                    <p className="text-red-500">{projectNameError}</p>
                  )}
                </div>
                  <div id="fileUpload" className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="file" value="Upload file" />
                  </div>
                  <FileInput
                    id="file"
                    accept=".png, .jpeg, .jpg"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      setSelectedFile(file);
                      const allowedTypes = [
                        "image/jpeg",
                        "image/png",
                        "image/svg+xml",
                      ];

                      if (file && allowedTypes.includes(file.type)) {
                        setFile(file);
                        setFileError(""); // Clear the file error when user selects a valid file
                        setIsFileValid(true);
                        const reader = new FileReader();
                        reader.onload = () => {
                          setPreviewImage(reader.result);
                        };
                        reader.readAsDataURL(file);
                      } else {
                        setFile(null);
                        setFileError(
                          "Please select a valid file type (jpg, png, svg)"
                        );
                        setIsFileValid(false); // Set file validity to false
                      }
                    }}
                  />

                  {previewImage && !edit && (
                    <div className="flex justify-center items-center h-48 w-48 mt-2 ml-16">
                      <img src={previewImage} alt="Selected Image" />
                    </div>
                  )}
                  {edit && (
                    <div className="flex justify-center items-center h-48 w-48 mt-2 ml-16">
                      <img
                        src={previewImage ? previewImage : editLogo}
                        alt="Selected Image"
                      />
                    </div>
                  )}
                  {fileError && <p className="text-red-500">{fileError}</p>}
               </div>
                <div className="flex w-full justify-center text-sm font-medium text-gray-500 dark:text-gray-300">
                  <button
                    onClick={edit ? handleEditProject : handleAddProject}
                    type="submit"
                    className="text-white  items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  font-medium  text-sm w-full sm:w-full px-5 py-2.5 "
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>*/}

          <Modal
            show={openModal}
            size="md"
            onClose={onCloseModal}
            popup
            position={modalPlacement}
            className="fixed projectmodal inset-0 py-[75%] sm:py-0 flex items-center justify-center z-50"
          >
            {/*  */}
            <Modal.Header>
              <h3 className="text-xl ml-[15px] font-medium text-gray-900 dark:text-white">
                {projectname ? "Create Project" : "Edit Project"}
              </h3>
            </Modal.Header>
            <Modal.Body className="centered">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  edit ? handleEditProject() : handleAddProject();
                }}
                className="space-y-6"
              >
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="name" value="Project Name" />
                  </div>
                  <TextInput
                    id="name"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                      setProjectNameError(""); // Clear the project name error when user types
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent form submission if necessary
                        edit ? handleEditProject() : handleAddProject(); // Trigger form submission manually
                      }
                    }}
                    required
                  />
                  {projectNameError && (
                    <p className="text-red-500">{projectNameError}</p>
                  )}
                </div>

                <div className="flex w-full justify-center text-sm font-medium text-gray-500 dark:text-gray-300">
                  <button
                    type="submit"
                    className="text-white items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-medium text-sm w-full sm:w-full px-5 py-2.5"
                  >
                    Save
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProjectTab;
