import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Tooltip } from '@blueprintjs/core';
import { t } from 'raeditor/utils/l10n';

export const DuplicateButton = observer(({ store }) => {
    const hasSelection = store.selectedElements.length > 0;

    const handleDuplicate = () => {
        const newElementIds = [];

        store.selectedElements.forEach((element) => {
            const clonedElement = element.clone();
            clonedElement.set({ name: clonedElement.id });
            if (element.type === 'group') {
                clonedElement.children.forEach((child) => {
                    child.set({ x: child.x + 50, y: child.y + 50 });
                });
            } else {
                clonedElement.set({ x: element.x + 50, y: element.y + 50 });
            }

            newElementIds.push(clonedElement.id);
        });

        store.selectElements(newElementIds);
    };

    return (
        <Tooltip
            content={t('toolbar.duplicateElements')}
            disabled={!hasSelection}
            position="bottom"
        >
            <Button
                icon="duplicate"
                minimal
                onClick={handleDuplicate}
                disabled={!hasSelection}
            />
        </Tooltip>
    );
});
