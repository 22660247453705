import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { RaeditorContainer, SidePanelWrap, WorkspaceWrap } from "raeditor";
import { ZoomButtons } from "raeditor/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS, SizeSection } from "./sections/side-panel";
import { Workspace } from "raeditor/canvas/workspace";
// for specific url
import { isAlive } from "mobx-state-tree";
import { getImageSize } from "raeditor/utils/image";
import { getCrop } from "raeditor/utils/image";
// ending here
import { UploadSection } from "./sections/upload-section";
import { Tooltip } from "./sections/tooltip";
import { PagesTimeline } from "raeditor/pages-timeline";
import { setTranslations } from "raeditor/config";
import { loadFile } from "./file";
import { IconsSection } from "./sections/icons-section";
import { ShapesSection } from "./sections/shapes-section";
import { DefaultTemplatesSection } from "./sections/default-templates-section";
import { PhotosSection } from "./sections/photos-section";
import { LayersSection } from "./sections/layers-panel";
import { TextSection } from "./sections/text-section";
import { useProject } from "./project";
import fr from "./translations/fr";
import en from "./translations/en";
import id from "./translations/id";
import ru from "./translations/ru";
import ptBr from "./translations/pt-br";
import Topbar from "./topbar/topbar";
import Toolbar from "./sections/toolbar";
import { RatingStarSection } from "./sections/ratingStarSection";
import { AiImageGenrate } from "./sections/AiImageGenrate";
import { ImageClipSection } from "./sections/image-clip-panel";
import { UserContext } from "./context/UserContext";

let DEFAULTSECTIONS = DEFAULT_SECTIONS.filter(
  (section) => section.name !== "templates"
);

DEFAULTSECTIONS = DEFAULTSECTIONS.filter((section) => section.name !== "icons");
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "elements"
);
// console.log(DEFAULTSECTIONS)
DEFAULTSECTIONS = DEFAULTSECTIONS.filter((section) => section.name !== "size");
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "photos"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "layers"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "upload"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter((section) => section.name !== "text");
DEFAULTSECTIONS.unshift(UploadSection);

DEFAULTSECTIONS.unshift(ShapesSection);

DEFAULTSECTIONS.unshift(TextSection);
DEFAULTSECTIONS.push(LayersSection);
DEFAULTSECTIONS.push(RatingStarSection);

DEFAULTSECTIONS.push(ImageClipSection);
DEFAULTSECTIONS.push(AiImageGenrate);

DEFAULTSECTIONS.unshift(IconsSection);
DEFAULTSECTIONS.unshift(PhotosSection);

DEFAULTSECTIONS.unshift(DefaultTemplatesSection);
DEFAULTSECTIONS.unshift(SizeSection);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight - 64);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = observer(({ store }) => {
  const navigate = useNavigate();
  const { projectId, templateId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const decodedImageUrl = queryParams.get("imageUrl");
  console.log("Image URL:", decodedImageUrl);
  const project = useProject();
  const height = useHeight();

  const { UserName, updateUser } = useContext(UserContext);
  React.useEffect(() => {
    if (project.language.startsWith("fr")) {
      setTranslations(fr);
    } else if (project.language.startsWith("id")) {
      setTranslations(id);
    } else if (project.language.startsWith("ru")) {
      setTranslations(ru);
    } else if (project.language.startsWith("pt")) {
      setTranslations(ptBr);
    } else {
      setTranslations(en);
    }
  }, [project.language]);

  const changeCanvasSize = (newWidth, newHeight) => {
    store.setSize(newWidth, newHeight); // Assuming setSize is a method in your store
  };

  // const handleImageClick1 = async (url, pos, element) => {
  //   console.log(url, "URL IS HERE");
  //   const response = await fetch(url, { mode: 'no-cors' });
  //   console.log(response,"response is here imageurls ie here")
  //   const { width: imageWidth, height: imageHeight } = await getImageSize(url);
  //   console.log("Calling this function", imageWidth);
  //   console.log("Calling this function", imageHeight);
  //   if (element && element.type === "image" && element.contentEditable) {
  //     const crop = getCrop(element, {
  //       width: imageWidth,
  //       height: imageHeight,
  //     });
  //     element.set(Object.assign({ src: url }, crop));
  //     return; 
  //   }
  //   console.log(
  //     `Calculated dimensions: width = ${imageWidth}, height = ${imageHeight}`
  //   );
  //   changeCanvasSize(imageWidth, imageHeight);
  //   store.history.transaction(async () => {
  //     const img = store.activePage?.addElement({
  //       type: "image",
  //       src: url,
  //       width: imageWidth,
  //       height: imageHeight,
  //     });

  //     if (isAlive(img)) {
  //       await img.set({ src: url });
  //     }
  //   });
  // };


  const handleImageClick = async (url, pos, element) => {
    try {
      const proxyUrl = `https://images.weserv.nl/?url=${(url)}`;
      const response = await fetch(proxyUrl);
      const { width: imageWidth, height: imageHeight } = await getImageSize(response.url);
      console.log("Calling this function", imageWidth);
      console.log("Calling this function", imageHeight);
      if (element && element.type === "image" && element.contentEditable) {
        const crop = getCrop(element, {
          width: imageWidth,
          height: imageHeight,
        });
        element.set(Object.assign({ src: response.url }, crop));
        return; 
      }
      console.log(
        `Calculated dimensions: width = ${imageWidth}, height = ${imageHeight}`
      );
      changeCanvasSize(imageWidth, imageHeight);
      store.history.transaction(async () => {
        const img = store.activePage?.addElement({
          type: "image",
          src: response.url,
          width: imageWidth,
          height: imageHeight,
        });
  
        if (isAlive(img)) {
          await img.set({ src: response.url });
        }
      });
  
      // Example: you can set this image URL in an <img> tag or use it elsewhere
      // document.getElementById('myImage').src = imageUrl;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };
  

  
  
  
  React.useEffect(() => {
    project.clear();
    project.name = "Untitled Design";
    project.projectId = projectId;
    if (decodedImageUrl) {
      handleImageClick(decodedImageUrl);
    }

    let defaultTemplateId = localStorage.getItem("templateId");
    let customSize = localStorage.getItem("customSize");
    if (templateId) {
      project.firstLoad(templateId, projectId);
    } else if (defaultTemplateId) {
      project.defaultLoadById(defaultTemplateId);
      localStorage.removeItem("templateId");
    } else if (customSize) {
      customSize = customSize.split("x");
      project.store.setSize(parseInt(customSize[0]), parseInt(customSize[1]));
      localStorage.removeItem("customSize");
    }
  }, []);
  const handleDrop = (ev) => {
    ev.preventDefault();

    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  const handleSave = async () => {
    try {
      await project.save();
    } catch (error) {
      console.error("Save failed is here app.js how can i do that :", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSave();
      console.log("Calling Is Here");
    }, 500);

    return () => clearTimeout(timer);
  }, []);




  return (
    <div
      className="bp5-dark"
      style={{
        width: "99vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Topbar store={store} />
      <div style={{ height: "calc(100% - 50px)" }}>
        <RaeditorContainer className="raeditor-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULTSECTIONS} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store} components={{ Tooltip }} />
            <ZoomButtons store={store} />
            <PagesTimeline store={store} />
          </WorkspaceWrap>
        </RaeditorContainer>
      </div>
    </div>
  );
});

export default App;
