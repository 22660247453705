import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { register } from "../APIservice/apiService";
import { nameCharLimit } from "../CharLimit";
import { UserContext } from "../context/UserContext";
import { Spinner } from "flowbite-react";

const Signup = () => {
  const navigate = useNavigate();
  const { UserName, updateUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const gologin = () => {
    navigate("/login");
  };

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const signupdetails = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const name = event.target.fullname.value;
    const email = event.target.email.value.toLowerCase(); // Access the email input value
    const password = event.target.password.value; // Access the password input value
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
    const validateEmail = (email) => {
      // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // const re = /^[A-Za-z0-9\s]+@gmail\.com$/;
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(email.toLowerCase());
    };

    // Form validation logic
    const errors = {};
    if (!name) {
      errors.fullname = "Full Name is required";
    } else if (!nameRegex.test(name)) {
      errors.fullname =
        "Full Name should contain only alphabetic characters without spaces.";
    } else if (name.length > nameCharLimit) {
      errors.fullname = `Name cannot exceed ${nameCharLimit} characters`;
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!validateEmail(email)) {
      errors.email = "Email is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8 || password.length > 16) {
      errors.password = "Password must be between 8 and 16 characters";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const response = await register(name, email, password);
      setIsLoading(false);
      if (response && response.data) {
        if (response.data?.error !== "Email already exists") {
          navigate("/dashboard");
          updateUser({
            name: response.data.name,
            email: response.data.email,
            credits: response.data.credits,
            plan: response.data.plan,
          });
        }
      }
    } catch (error) {
      // If the email is already in use, handle the error and do not redirect
      if (error.response && error.response.status === 409) {
        // Assuming 409 is the conflict code for email already in use
        setFormErrors({ email: "Email is already in use" });
        console.log("error");
      } else {
        console.error("Signup failed:", error);
      }
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="hidden hide-on-specific-dimensions p-5 lg:flex items-center justify-center flex-1 bg-[#1F2937] text-black">
          <img
            className="h-full rounded-lg w-full"
            src="https://images.pexels.com/photos/17350364/pexels-photo-17350364/free-photo-of-a-computer-in-a-room.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            alt=""
          />
        </div>

        <div className="w-full bg-[#111827] lg:w-1/2 hide-on-specific-dimensions-width flex items-center justify-center">
          <div className="max-w-lg bg-[#1F2937] rounded-xl w-full p-6">
            <div className="flex items-center mb-6">
              <img
                src="https://cdn.pixabay.com/photo/2017/02/15/00/48/logo-2067396_640.png" // Replace with your logo URL
                alt="Logo"
                className="h-14" // Adjust the size of the logo
              />
              <h1 className=" ml-3 text-3xl font-bold text-white">
                Dynamic Graphic Engine
              </h1>
            </div>
            <h1 className="text-2xl font-semibold mb-6 text-white text-center">
              Sign Up
            </h1>

            <form onSubmit={signupdetails} method="POST" className="space-y-4">
              <div>
                <label
                  for="fullname"
                  className="block text-sm font-medium text-white"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullname"
                  value={formData.fullname}
                  onChange={(e) => {
                    setFormData({ ...formData, fullname: e.target.value });
                    setFormErrors({ ...formErrors, fullname: "" }); // Clear the error message for fullname
                  }}
                  name="fullname"
                  className="mt-1 bg-[#2F3D53] mb-0 p-2 w-full text-white border rounded-md"
                />
                {formErrors.fullname && (
                  <p className="text-red-500 mb-0">{formErrors.fullname}</p>
                )}
              </div>
              <div>
                <label
                  for="email"
                  className="block text-sm font-medium text-white"
                >
                  Email
                </label>
                <input
                  id="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    setFormErrors({ ...formErrors, email: "" }); // Clear the error message for email
                  }}
                  name="email"
                  className="mt-1 mb-0 bg-[#2F3D53] p-2 w-full text-white border rounded-md "
                />

                {formErrors.email && (
                  <p className="text-red-500 mb-0">{formErrors.email}</p>
                )}
              </div>
              <div>
                <label
                  for="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    setFormErrors({ ...formErrors, password: "" }); // Clear the error message for password
                  }}
                  className="mt-1 bg-[#2F3D53] mb-0 p-2 text-white w-full border rounded-md "
                />
                {formErrors.password && (
                  <p className="text-red-500 mb-0">{formErrors.password}</p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full mt-2 bg-[#1A56DB] text-white p-2 rounded-md font-bold hover:bg-[#1a57dbb6] hover:text-white focus:outline-none focus:ring-gray-900 transition-colors duration-300"
                >
                  {/* Sign Up */}
                  {isLoading ? (
                    <>
                      <Spinner
                        aria-label="Default status example"
                        color="info"
                      />{" "}
                      <span className="pl-3">Loading...</span>
                    </>
                  ) : (
                    "Sign Up"
                  )}
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-white text-center">
              <p>
                Already have an account?{" "}
                <a
                  className="text-blue-500 hover:text-blue-700 font-bold hover:underline border-white"
                  onClick={gologin}
                >
                  Login here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
