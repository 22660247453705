import { toast } from 'react-toastify';

let timeoutId;

const setSessionTimeout = (timeoutDuration, logout) => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
        logout();
        toast.info('Session expired due to inactivity.');
    }, timeoutDuration);
};

const resetSessionTimeout = (timeoutDuration, logout) => {
    setSessionTimeout(timeoutDuration, logout);
};

const initSessionManagement = (timeoutDuration, logout) => {
    setSessionTimeout(timeoutDuration, logout);

    const events = ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
        window.addEventListener(event, () => resetSessionTimeout(timeoutDuration, logout));
    });

    return () => {
        clearTimeout(timeoutId);
        events.forEach(event => {
            window.removeEventListener(event, () => resetSessionTimeout(timeoutDuration, logout));
        });
    };
};

export { initSessionManagement, resetSessionTimeout };
