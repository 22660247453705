import React from "react";
import { observer } from "mobx-react-lite";
import * as svg from "raeditor/utils/svg";
import { ElementContainer, extendToolbar } from "raeditor/toolbar/element-container";
import FiltersPicker from "raeditor/toolbar/filters-picker";
import ColorPicker from "raeditor/toolbar/color-picker";
import FlipButton from "raeditor/toolbar/flip-button";
import { AnimationsPicker } from "raeditor/toolbar/animations-picker";
import { flags } from "raeditor/utils/flags";

const findColorInKeys = (colorsReplace, color) =>
    Array.from(colorsReplace.keys()).find((key) => svg.sameColors(key, color));

const getColor = (colorsReplace, color) => {
    const colorKey = findColorInKeys(colorsReplace, color);
    return colorsReplace.get(colorKey || "") || color;
};

const SvgColors = observer(({ element, elements, store }) => {
    const colors = svg.useSvgColors(element.src);
    return (
        <>
            {!element.maskSrc &&
                colors.slice(0, 5).map((color) => (
                    <ColorPicker
                        key={color}
                        value={getColor(element.colorsReplace, color)}
                        style={{ marginLeft: "5px" }}
                        onChange={(newColor) => {
                            const colorKey = findColorInKeys(element.colorsReplace, color) || color;
                            element.replaceColor(colorKey, newColor);
                        }}
                        store={store}
                        gradientEnabled
                    />
                ))}
        </>
    );
});

const PROPS_MAP = {
    SvgFlip: FlipButton,
    SvgFilters: FiltersPicker,
    SvgColors: SvgColors,
    SvgAnimations: AnimationsPicker
};

const SvgToolbar = observer(({ store, hideSvgEffects, components }) => {
    const selectedElements = store.selectedElements;
    const usedItems = [
        "SvgFlip",
        !hideSvgEffects && "SvgFilters",
        "SvgColors",
        flags.animationsEnabled && "SvgAnimations"
    ].filter(Boolean);

    const items = extendToolbar({
        type: "svg",
        usedItems,
        components
    });

    return (
        <ElementContainer
            items={items}
            itemRender={(item) => {
                const Component = components[item] || PROPS_MAP[item];
                return <Component elements={selectedElements} element={selectedElements[0]} store={store} key={item} />;
            }}
        />
    );
});

export { SvgToolbar };
export default SvgToolbar;
