import React from "react";

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px",color:"white" }}>
      <h1>404 - Page Not Found</h1>
      <p> 😊 😊 </p>
    </div>
  );
};

export default NotFoundPage;
