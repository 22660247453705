import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';
const PrivateRoute = () => {
    // const token = localStorage.getItem("token");
    const token = Cookies.get('token');
    // const token = sessionStorage.getItem('token');
    return token ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute
