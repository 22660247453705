import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { Callout } from '@blueprintjs/core';
import {
  ImagesGrid,
  UploadSection as DefaultUploadSection,
} from 'raeditor/side-panel';
import { getImageSize, getCrop } from 'raeditor/utils/image';
import { getVideoSize, getVideoPreview } from 'raeditor/utils/video';
import { dataURLtoBlob } from '../blob';

import { CloudWarning } from '../cloud-warning';

import { useProject } from '../project';
// import { listImages, uploadImage, deleteImage } from '../api';
import { listImages , uploadImage , deleteImage } from "../api"

function getType(file) {
  const { type } = file;
  
  if (type.indexOf('svg') >= 0) {
    return 'svg';
  }
  if (type.indexOf('image') >= 0) {
    return 'image';
  }
  if (type.indexOf('video') >= 0) {
    return 'video';
  }
  return 'images';
}

const getImageFilePreview = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target.result;
      // now we need to render that image into smaller canvas and get data url
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 200;
        canvas.height = (200 * img.height) / img.width;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };
      img.src = url;
      // console.log(url,"URL IS HERE OF IMAGE UPLOAD")
    };
    reader.readAsDataURL(file);
  });
};

export const UploadPanel = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  const [isUploading, setUploading] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const project = useProject();

  const load = async () => {
    setLoading(true);
    // const images = await listImages();
    setImages(images);
    setLoading(false);
  };

  const updateSessionStorage = (newImages) => {
    sessionStorage.setItem('uploadedImages', JSON.stringify(newImages));
  };
  
  React.useEffect(() => {
    const storedImages = sessionStorage.getItem('uploadedImages');
    if (storedImages) {
      setImages(JSON.parse(storedImages));
    }
  }, []);

  const handleFileInput = async (e) => {
    const { target } = e;
    setUploading(true);
    setErrorMessage(''); // Clear the error message on each file upload attempt

    for (const file of target.files) {

      const fileType = getType(file);
     
      if (fileType !== 'image') {
        setErrorMessage('Please upload only images (PNG, JPEG).');
        target.value = null; // Reset file input
        setUploading(false);
        return; // Exit the loop if the file is not an image
      }
      // Check if file size is less than 30 MB
      if (file.size > 20 * 1024 * 1024) {
        setErrorMessage('File size must be less than 20 MB.');
        // alert('File size must be less than 30 MB.');
        target.value = null; // Reset file input so the same file can be selected again
        setUploading(false);
        return; // Exit if file is too large
      }
      const type = getType(file);
      let previewDataURL = '';
      if (type === 'video') {
        previewDataURL = await getVideoPreview(URL.createObjectURL(file));
      } else {
        previewDataURL = await getImageFilePreview(file);
      }
      const preview = dataURLtoBlob(previewDataURL);
      // await uploadImage({ file, preview, type });
      const result = await uploadImage({ file });
      console.log("File upload successful:", result);
      // setImages(prevImages => [...prevImages, {
      //   id: Date.now(), // Use a temporary unique ID
      //   src: result.data.url,
      //   preview: previewDataURL,
      //   type: type
      // }]);
      setImages(prevImages => {
        const newImages = [...prevImages, {
          id: Date.now(), // Use a temporary unique ID
          src: result.data.url,
          preview: previewDataURL,
          type: type
        }];
        updateSessionStorage(newImages);
        return newImages;
      });
      
    }
    // await load();
    setUploading(false);
    target.value = null;
  };

  // const handleDelete = async (image) => {
  //   if (window.confirm('Are you sure you want to delete the image?')) {
  //     setImages(images.filter((i) => i.id !== image.id));
  //     await deleteImage({ id: image.id });
  //     await load();
  //   }
  // };
  const handleDelete = async (image) => {
    if (window.confirm('Are you sure you want to delete the image?')) {
      setImages(prevImages => {
        const newImages = prevImages.filter((i) => i.id !== image.id);
        updateSessionStorage(newImages);
        return newImages;
      });
      await deleteImage({ id: image.id });
    }
  }

  // React.useEffect(() => {
  //   load();
  // }, []);

  // React.useEffect(() => {
  //   load();
  // }, [project.cloudEnabled]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    {/*  {errorMessage && (
          <Callout intent="danger" title="Error">
            {errorMessage}
          </Callout>
      )}*/}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="input-file">
          <Button
            icon="upload"
            style={{ width: '100%' }}
            onClick={() => {
              document.querySelector('#input-file')?.click();
            }}
            loading={isUploading}
            intent="primary"
          >
            Upload Image
          </Button>
          <input
            type="file"
            id="input-file"
            style={{ display: 'none' }}
            onChange={handleFileInput}
            accept="image/png, image/jpeg"
            // multiple
          />


        </label>
        {/* Show error message if exists */}
        {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              {errorMessage}
            </div>
        )}
      </div>
      {/* <CloudWarning /> */}
      {/* {console.log(images)} */}
      <ImagesGrid
        images={images}
        getPreview={(image) => image.src}
        crossOrigin={undefined}
        isLoading={isLoading}
        // getCredit={(image) => (
        //   <div>
        //     <Button icon="trash" onClick={() => handleDelete(image)}></Button>
        //   </div>
        // )}
        onSelect={async (item, pos, element) => {
          const image = item.src;
          const type = item.type;

          const getSizeFunc = type === 'video' ? getVideoSize : getImageSize;

          let { width, height } = await getSizeFunc(image);

          if (
            element &&
            element.type === 'svg' &&
            element.contentEditable &&
            type === 'image'
          ) {
            element.set({ maskSrc: image });
            return;
          }

          if (
            element &&
            element.type === 'image' &&
            element.contentEditable &&
            type == 'image'
          ) {
            const crop = getCrop(element, {
              width,
              height,
            });
            element.set({ src: image, ...crop });
            return;
          }

          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;

          store.activePage?.addElement({
            type,
            src: image,
            x,
            y,
            width,
            height,
          });
        }}
      />
    </div>
  );
});

DefaultUploadSection.Panel = UploadPanel;

export const UploadSection = DefaultUploadSection;
