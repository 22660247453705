import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'raeditor/side-panel';
import { Button, Tabs, Tab } from '@blueprintjs/core';
import useSWR from 'swr';
import { t } from 'raeditor/utils/l10n';
import styled from 'raeditor/utils/styled';
import { isMobile } from 'raeditor/utils/screen';
import { ImagesGrid } from 'raeditor/side-panel/images-grid';
import { textTemplateList } from 'raeditor/utils/api';
import { fetcher } from 'raeditor/utils/use-api';
import { registerNextDomDrop } from 'raeditor/canvas/page';
import FaTextHeight from '@meronex/icons/fa/FaTextHeight'; // Icon for the Text Tab

const Container = styled('div')`
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;

    .bp5-dark & .raeditor-text-preview-plain {
        filter: invert(1);
    }
`;

const FontContainer = styled('div')`
    height: 100px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(16, 22, 26, 0.3);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    position: relative;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
    margin-bottom: 10px;
`;

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const FontItem = observer(({ onSelect, onRemove, font }) => (
    <FontContainer
        style={{ fontFamily: font.fontFamily }}
        className="raeditor-font-item"
        onClick={onSelect}
    >
        {font.fontFamily} text
        <Button
            style={{ position: 'absolute', right: 0, bottom: 0 }}
            minimal
            icon="trash"
            onClick={(e) => {
                e.stopPropagation();
                onRemove();
            }}
        />
    </FontContainer>
));

const DragButton = ({ onSelect, ...props }) => (
    <Button
        {...props}
        draggable
        className="raeditor-close-panel"
        onClick={() => onSelect()}
        onDragStart={() => {
            registerNextDomDrop(({ x, y }) => {
                onSelect({ x, y });
            });
        }}
        onDragEnd={() => {
            registerNextDomDrop(null);
        }}
    />
);

export const TextPanel = observer(({ store }) => {
    const [activeTab, setActiveTab] = useState('text');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        store.loadFont('Roboto');
    }, [store]);

    const addTextElement = (options) => {
        const width = options.width || store.width / 2;
        const x = (options.x || store.width / 2) - width / 2;
        const y = (options.y || store.height / 2) - options.fontSize / 2;
        const scaleFactor = (store.width + store.height) / 2160;

        const element = store.activePage?.addElement({
            type: 'text',
            fontFamily: 'Roboto',
            ...options,
            x,
            y,
            width,
            fontSize: options.fontSize * scaleFactor,
        });

        if (!isMobile() && element) {
            element.toggleEditMode(true);
        }
    };

    useEffect(() => {
        store.fonts.forEach((font) => store.loadFont(font.fontFamily));
    }, [store, store.fonts]);

    const { data, error } = useSWR(textTemplateList(), fetcher);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Tabs large onChange={(id) => setActiveTab(id)} selectedTabId={activeTab}>
                <Tab id="text" title={t('sidePanel.text')} />
                <Tab id="font" title={t('sidePanel.myFonts')} />
            </Tabs>
            {activeTab === 'text' && (
                <Container>
                    <DragButton
                        style={{
                            marginBottom: '5px',
                            width: '100%',
                            fontSize: '25px',
                            fontFamily: 'Roboto',
                        }}
                        minimal
                        onSelect={(pos) =>
                            addTextElement({
                                ...pos,
                                fontSize: 76,
                                text: t('sidePanel.headerText'),
                                fontFamily: 'Roboto',
                            })
                        }
                    >
                        {t('sidePanel.createHeader')}
                    </DragButton>
                    <DragButton
                        style={{
                            marginBottom: '5px',
                            width: '100%',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                        }}
                        minimal
                        onSelect={(pos) =>
                            addTextElement({
                                ...pos,
                                fontSize: 44,
                                text: t('sidePanel.subHeaderText'),
                                fontFamily: 'Roboto',
                            })
                        }
                    >
                        {t('sidePanel.createSubHeader')}
                    </DragButton>
                    <DragButton
                        style={{
                            marginBottom: '5px',
                            width: '100%',
                            fontSize: '14px',
                            fontFamily: 'Roboto',
                        }}
                        minimal
                        onSelect={(pos) =>
                            addTextElement({
                                ...pos,
                                fontSize: 30,
                                text: t('sidePanel.bodyText'),
                                fontFamily: 'Roboto',
                            })
                        }
                    >
                        {t('sidePanel.createBody')}
                    </DragButton>
                    <ImagesGrid
                        shadowEnabled={false}
                        images={data?.items}
                        getPreview={(item) => item.preview}
                        getImageClassName={(item) =>
                            item.json.includes('plain') ? 'raeditor-text-preview-plain' : ''
                        }
                        isLoading={!data}
                        error={error}
                        onSelect={async (item, pos) => {
                            const response = await fetch(item.json);
                            const jsonData = await response.json();
                            if (!store.activePage) return;

                            const scaleFactor = (store.width + store.height) / 2160;
                            const x =
                                (pos?.x || store.width / 2) - (jsonData.width / 2) * scaleFactor;
                            const y =
                                (pos?.y || store.height / 2) - (jsonData.height / 2) * scaleFactor;

                            store.history.transaction(() => {
                                const elements = jsonData.pages[0].children;
                                const newElementIds = [];

                                elements.forEach((elementData) => {
                                    delete elementData.id;
                                    const newElement = store.activePage?.addElement({
                                        ...elementData,
                                        fontSize: elementData.fontSize * scaleFactor,
                                        x: elementData.x * scaleFactor + x,
                                        y: elementData.y * scaleFactor + y,
                                        width: elementData.width * scaleFactor,
                                        height: elementData.height * scaleFactor,
                                    });
                                    if (newElement) newElementIds.push(newElement.id);
                                });
                                store.selectElements(newElementIds);
                            });
                        }}
                    />
                </Container>
            )}
            {activeTab === 'font' && (
                <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 50px)' }}>
                    <label htmlFor="raeditor-font-upload">
                        <Button
                            icon="upload"
                            intent="primary"
                            style={{ width: '100%',marginTop:'4px' }}
                            onClick={() => {
                                document.getElementById('raeditor-font-upload').click();
                            }}
                        >
                            {t('sidePanel.uploadFont')}
                        </Button>
                        <input
                            type="file"
                            accept=".ttf, .otf, .woff, .woff2, .eot"
                            id="raeditor-font-upload"
                            style={{ display: 'none' }}
                            onChange={async (e) => {
                                const { files } = e.target;
                                let invalidFile = false;

                                for (const file of files) {
                                    // Validation: check file extension
                                    const validExtensions = ['.ttf', '.otf', '.woff', '.woff2', '.eot'];
                                    const fileName = file.name.toLowerCase();
                                    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
                                    if (!validExtensions.includes(fileExtension)) {
                                        invalidFile = true;
                                        continue; // Skip this file
                                    }
                                    // Optional: check MIME type
                                    const validMimeTypes = [
                                        'font/ttf',
                                        'font/otf',
                                        'font/woff',
                                        'font/woff2',
                                        'application/x-font-ttf',
                                        'application/x-font-otf',
                                        'application/x-font-woff',
                                        'application/x-font-woff2',
                                        'application/vnd.ms-fontobject',
                                    ];
                                    if (file.type && !validMimeTypes.includes(file.type)) {
                                        invalidFile = true;
                                        continue; // Skip this file
                                    }
                                    // Clear any previous error message
                                    setErrorMessage('');
                                    const url = await toBase64(file);
                                    const fontFamily = file.name.split('.')[0].replace(/,/g, '');
                                    store.addFont({ fontFamily, url });
                                }

                                if (invalidFile) {
                                    setErrorMessage('Invalid file type. Please upload valid font files.');
                                } else {
                                    setErrorMessage('');
                                }

                                e.target.value = null;
                            }}
                        />
                    </label>
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
                    )}
                    <div style={{ paddingTop: '20px', overflow: 'auto', height: '100%' }}>
                        {store.fonts.map((font, index) => (
                            <FontItem
                                font={font}
                                key={index}
                                onSelect={() =>
                                    addTextElement({
                                        fontSize: 80,
                                        text: 'Cool text',
                                        fontFamily: font.fontFamily,
                                    })
                                }
                                onRemove={() => {
                                    // store.elements.forEach((element) => {
                                    //     if (element.type === 'text' && element.fontFamily === font.fontFamily) {
                                    //         element.set({ fontFamily: 'Roboto' });
                                    //     }
                                    // });
                                    // store.removeFont(font.fontFamily);
                                    if (store.elements) {
                                        store.elements.forEach((element) => {
                                            if (element.type === 'text' && element.fontFamily === font.fontFamily) {
                                                element.set({ fontFamily: 'Roboto' });
                                            }
                                        });
                                    }
                                    store.removeFont(font.fontFamily);
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

// Define the new custom section
export const TextSection = {
    name: 'text',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.text')} {...props}>
            <FaTextHeight className="w-5 h-5" />
        </SectionTab>
    )),
    Panel: TextPanel,
};
