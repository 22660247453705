"use strict";
import React from "react";
import { observer } from "mobx-react-lite";
import { Navbar, NavbarGroup, NavbarDivider, Alignment, InputGroup } from "@blueprintjs/core";
import styled from "raeditor/utils/styled";
import { mobileStyle } from "raeditor/utils/screen";
import { HistoryButtons } from "raeditor/toolbar/history-buttons";
import { TextToolbar } from "raeditor/toolbar/text-toolbar";
import { HtmlToolbar } from "raeditor/toolbar/html-toolbar";
import { ImageToolbar } from "./image-toolbar";
import { SvgToolbar } from "./svg-toolbar";
import { LineToolbar } from "raeditor/toolbar/line-toolbar";
import { VideoToolbar } from "raeditor/toolbar/video-toolbar";
import { FigureToolbar } from "raeditor/toolbar/figure-toolbar";
import { ManyToolbar } from "raeditor/toolbar/many-toolbar";
import { PageToolbar } from "raeditor/toolbar/page-toolbar";
import { DownloadButton } from "raeditor/toolbar/download-button";
import { DuplicateButton } from "raeditor/toolbar/duplicate-button";
import { RemoveButton } from "raeditor/toolbar/remove-button";
import { LockButton } from "raeditor/toolbar/lock-button";
import { PositionPicker } from "raeditor/toolbar/position-picker";
import { OpacityPicker } from "raeditor/toolbar/opacity-picker";
import { AdminButton } from "raeditor/toolbar/admin-button";
import { GroupButton } from "raeditor/toolbar/group-button";
import { flags } from "raeditor/utils/flags";
import {RatingStarToolbar} from "./ratingStarToolbar";

const ComponentsTypes = {
    text: TextToolbar,
    image: ImageToolbar,
    svg: SvgToolbar,
    many: ManyToolbar,
    line: LineToolbar,
    video: VideoToolbar,
    figure: FigureToolbar,
    page: PageToolbar,
};

export function registerToolbarComponent(type, component) {
    ComponentsTypes[type] = component;
}

const NavbarContainer = styled("div")`
  white-space: nowrap;
  ${(mobileStyle)`
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
  `}
`;

const NavInner = styled("div")`
  width: 100%;
  height: 100%;
  ${(mobileStyle)`
    display: flex;
  `}
`;

export const Toolbar = observer(({ store, downloadButtonEnabled, components = {} }) => {
    const isSingleElementSelected = store.selectedElements.length === 1;
    const firstSelectedElement = store.selectedElements[0];
    const isEditable = store.selectedElements.every(element => element.styleEditable);
    let ActiveToolbar = isEditable && isSingleElementSelected && ComponentsTypes[firstSelectedElement.type];

    if (isSingleElementSelected && firstSelectedElement.type === "text" && flags.htmlRenderEnabled) {
        ActiveToolbar = HtmlToolbar;
    } else if (store.selectedElements.length > 1) {
        ActiveToolbar = ManyToolbar;
    } else if (store.selectedElements.length === 0) {
        ActiveToolbar = PageToolbar;
    } else if (firstSelectedElement.custom?.isRatingStar === true){
        ActiveToolbar = RatingStarToolbar;
    }

    const showCropMode = isSingleElementSelected && firstSelectedElement._cropModeEnabled;
    const ActionControls = components.ActionControls || (downloadButtonEnabled ? DownloadButton : null);
    const Position = components.Position || PositionPicker;
    const Opacity = components.Opacity || OpacityPicker;
    const Lock = components.Lock || LockButton;
    const Duplicate = components.Duplicate || DuplicateButton;
    const Remove = components.Remove || RemoveButton;
    const Group = components.Group || GroupButton;
    const History = components.History || HistoryButtons;
    const Admin = components.Admin || AdminButton;

    return (
        <NavbarContainer className="bp5-navbar">
            <NavInner>
                {!showCropMode && <History store={store} />}
                {ActiveToolbar && isEditable && <ActiveToolbar store={store} components={components} />}
                {!showCropMode && (
                    <NavbarGroup align={Alignment.RIGHT}>
                        {store.role === "admin" && <Admin store={store} />}
                        <Group store={store} />
                        <Position store={store} />
                        {isEditable && <Opacity store={store} />}
                        <Lock store={store} />
                        <Duplicate store={store} />
                        <Remove store={store} />
                        {ActionControls && (
                            <>
                                <NavbarDivider style={{ height: "100%", margin: "0 15px" }} />
                                <ActionControls store={store} />
                            </>
                        )}
                    </NavbarGroup>
                )}
            </NavInner>
        </NavbarContainer>
    );
});

export default Toolbar;
