import React, { useRef, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from '@blueprintjs/core';
import styled from 'raeditor/utils/styled';
import { mobileStyle, useMobile } from 'raeditor/utils/screen';
import FaShapes from '@meronex/icons/fa/FaShapes';
import FdPageMultiple from '@meronex/icons/fd/FdPageMultiple';
import { t } from 'raeditor/utils/l10n';
import { SectionTab } from 'raeditor/side-panel/tab-button';
import { ImagesGrid } from 'raeditor/side-panel/images-grid';
import { TextPanel } from 'raeditor/side-panel/text-panel';
import { SizePanel } from 'raeditor/side-panel/size-panel';
import { UploadPanel } from 'raeditor/side-panel/upload-panel';
import { PhotosPanel } from 'raeditor/side-panel/photos-panel';
import { ElementsPanel } from 'raeditor/side-panel/elements-panel';
import { PagesPanel } from 'raeditor/side-panel/pages-panel';
import { LayersPanel } from 'raeditor/side-panel/layers-panel';
import { BackgroundPanel } from './background-panel';
import { DefaultTemplatesSection } from './default-templates-section';

// EXPORT
export { SectionTab } from 'raeditor/side-panel/tab-button';
export { ImagesGrid } from 'raeditor/side-panel/images-grid';

export const TemplatesSection = {
    name: 'templates',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.templates')} {...props}>
            <Icon icon="control" />
        </SectionTab>
    )),
    Panel: ({ store }) => <DefaultTemplatesSection store={store} />,
};

export const TextSection = {
    name: 'text',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.text')} {...props}>
            <Icon icon="new-text-box" />
        </SectionTab>
    )),
    Panel: ({ store }) => <TextPanel store={store} />,
};

export const PhotosSection = {
    name: 'photos',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.photos')} {...props}>
            <Icon icon="media" />
        </SectionTab>
    )),
    Panel: ({ store }) => <PhotosPanel store={store} />,
};

export const ElementsSection = {
    name: 'elements',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.elements')} iconSize={16} {...props}>
      <span className="bp5-icon">
        <FaShapes />
      </span>
        </SectionTab>
    )),
    Panel: ({ store }) => <ElementsPanel store={store} />,
};

export const UploadSection = {
    name: 'upload',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.upload')} {...props}>
            <Icon icon="cloud-upload" />
        </SectionTab>
    )),
    Panel: ({ store }) => <UploadPanel store={store} />,
};

export const BackgroundSection = {
    name: 'background',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.background')} {...props}>
            <Icon icon="layout-grid" />
        </SectionTab>
    )),
    Panel: ({ store }) => <BackgroundPanel store={store} />,
};

export const PagesSection = {
    name: 'pages',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.pages')} {...props}>
            <FdPageMultiple />
        </SectionTab>
    )),
    Panel: ({ store }) => <PagesPanel store={store} />,
    visibleInList: false, // Not visible in the main list
};

export const LayersSection = {
    name: 'layers',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.layers')} {...props}>
            <Icon icon="layers" />
        </SectionTab>
    )),
    Panel: ({ store }) => <LayersPanel store={store} />,
};

export const SizeSection = {
    name: 'size',
    Tab: observer((props) => (
        <SectionTab name={t('Layout')} {...props}>
            <Icon icon="fullscreen" />
        </SectionTab>
    )),
    Panel: ({ store }) => <SizePanel store={store} />,
};

// MoreTab is an example of an additional tab
const MoreTab = observer((props) => (
    <SectionTab name={t('sidePanel.more')} {...props}>
        <Icon icon="more" />
    </SectionTab>
));

// Default sections to be used in the SidePanel
export const DEFAULT_SECTIONS = [
    TemplatesSection,
    TextSection,
    PhotosSection,
    ElementsSection,
    UploadSection,
    BackgroundSection,
    LayersSection,
    SizeSection,
];

const SidePanelContainer = styled('div')`
  display: flex;
  height: 100% !important;
  padding: 0px !important;
  position: relative;

  ${mobileStyle(`
    height: auto !important;
    width: 100%;
    position: relative;
  `)}
`;

const TabsWrap = styled('div', React.forwardRef)`
  @media screen and (min-width: 501px) {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 72px;
  }
  ${mobileStyle(`
    width: 100%;
    overflow: auto;
  `)}
`;

const TabsContainer = styled('div', React.forwardRef)`
  display: flex;
  flex-direction: column;

  ${mobileStyle(`
    flex-direction: row;
    min-width: min-content;
  `)}
`;

const PanelContainer = styled('div')`
  padding: 10px 10px 0px 10px !important;
  height: 100% !important;

  &.bp5-navbar {
    width: 350px;
  }

  &.bp5-navbar.collapsed {
    width: 0px;
  }

  ${mobileStyle(`
    &.bp5-navbar {
      position: absolute;
      bottom: 54px;
      z-index: 100;
      height: 50vh !important;
      width: 100%;
    }
  `)}
`;

const MobileOverlay = styled('div')`
  display: none;

  ${mobileStyle(`
    position: absolute;
    bottom: 72px;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.05);
  `)}
`;

const HideButtonContainer = styled('div')`
  position: absolute;
  right: -14px;
  top: 50%;
  height: 96px;
  width: 15px;
  fill: white;
  cursor: pointer;
  z-index: 10;

  .bp5-dark & {
    right: -13px;
  }

  & .stroke {
    stroke: rgba(0, 0, 0, 0.3);
    fill: none;
  }

  & .fill {
    fill: white;
  }

  .bp5-dark & .fill {
    fill: #2f343c;
  }

  & .pointer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleY(2);
    font-size: 0.5rem;
    color: rgba(171, 179, 191, 0.6);
  }

  .bp5-dark & .pointer {
    color: rgba(171, 179, 191, 0.6);
  }

  &:hover .pointer {
    color: black;
  }

  .bp5-dark &:hover .pointer {
    color: white;
  }

  ${mobileStyle(`
    display: none;
  `)}
`;

const HideButton = ({ onClick }) => (
    <HideButtonContainer onClick={onClick}>
        <svg
            width="15"
            height="96"
            viewBox="0 0 16 96"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill stroke"
                d="M 1 7 C 2 9 4 10 7.2 13.28 C 7.45 13.4625 7.6 13.6 7.7 13.8048 L 7.8 13.8 C 9.8 15.8 11.6 17.6 12.9 19.7 C 14 21.6 14.7 23.9 14.9 27 H 15 V 68 C 15 71.7 14.3 74.3 13 76.6 C 11.7 78.8 9.9 80.5 7.8 82.6344 L 7.79 82.6 C 7.6 82.8 7.4507 83 7.2729 83.2127 C 3.9102 86.5228 2 87 1 90"
            />
        </svg>
        <div className="pointer">&lt;</div>
    </HideButtonContainer>
);

const useOnFirstRender = (callback) => {
    const isFirstRender = React.useRef(true);
    if (isFirstRender.current) {
        isFirstRender.current = false;
        callback();
    }
};

export const SidePanel = observer(({ store, sections, defaultSection }) => {
    // Open the default side panel on first render
    useOnFirstRender(() => {
        store.openSidePanel(defaultSection || 'photos');
    });

    const allSections = sections || DEFAULT_SECTIONS;
    const visibleSections = allSections.filter(
        (section) => section.visibleInList !== false
    );

    const activeSection = allSections.find(
        (section) => section.name === store.openedSidePanel
    );
    const ActivePanel = activeSection ? activeSection.Panel : null;

    const isMobile = useMobile();

    // Adjust the opened side panel when the device changes
    useLayoutEffect(() => {
        if (isMobile) {
            store.openSidePanel('');
        } else {
            store.openSidePanel(defaultSection || 'photos');
        }
    }, [isMobile]);

    const tabsRef = useRef(null);

    return (
        <SidePanelContainer
            className={`bp5-navbar raeditor-side-panel${
                store.openedSidePanel ? '' : ' collapsed'
            }`}
        >
            <TabsWrap ref={tabsRef} className="raeditor-side-tabs-container">
                <TabsContainer className="raeditor-side-tabs-inner">
                    {visibleSections.map(({ name, Tab }) => (
                        <Tab
                            key={name}
                            active={name === store.openedSidePanel}
                            onClick={() => {
                                if (name === store.openedSidePanel && isMobile) {
                                    store.openSidePanel('');
                                } else {
                                    store.openSidePanel(name);
                                }
                            }}
                        />
                    ))}
                </TabsContainer>
            </TabsWrap>
            {ActivePanel && (
                <PanelContainer
                    className="bp5-navbar raeditor-panel-container"
                    onClick={(event) => {
                        const closeButton = event.target.closest('.raeditor-close-panel');
                        const mobilePanel = event.target.closest('.raeditor-mobile');
                        if (closeButton && (isMobile || mobilePanel)) {
                            store.openSidePanel('');
                        }
                    }}
                >
                    <ActivePanel store={store} />
                </PanelContainer>
            )}
            {store.openedSidePanel && (
                <>
                    <MobileOverlay onClick={() => store.openSidePanel('')} />
                    <HideButton onClick={() => store.openSidePanel('')} />
                </>
            )}
        </SidePanelContainer>
    );
});

export default SidePanel;
