import React, { useState, useContext } from "react";
import { login } from "../APIservice/apiService";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { Spinner } from "flowbite-react";

const Login = () => {
  const navigate = useNavigate();
  const { UserName, updateUser } = useContext(UserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const gosignup = () => navigate("/signup");
  const gosignup1 = () => navigate("/forgot");

  const logindetails = async (event) => {
    event.preventDefault();
    const validateEmail = (email) => {
      // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(email.toLowerCase());
    };
    const errors = {};
    console.log(formData.email);
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.password) errors.password = "Password is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const response = await login(formData.email, formData.password);
      setIsLoading(false);
      if (response.name) {
        navigate("/dashboard");
        updateUser({
          name: response.name,
          email: response.email,
          credits: response.credits,
          plan: response.plan,
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="hidden p-5 lg:flex items-center justify-center flex-1 bg-[#1F2937] text-black hide-on-specific-dimensions">
          <img
            className="h-full rounded-lg w-full"
            src="https://images.pexels.com/photos/12786601/pexels-photo-12786601.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            alt=""
          />
        </div>

        <div className="w-full bg-[#111827] lg:w-1/2 hide-on-specific-dimensions-width flex items-center justify-center h-screen">
          <div className="max-w-lg bg-[#1F2937] rounded-xl w-full p-6">
            <div className="flex items-center mb-6">
              <img
                src="https://cdn.pixabay.com/photo/2017/02/15/00/48/logo-2067396_640.png"
                alt="Logo"
                className="h-14"
              />
              <h1 className="ml-3 text-3xl font-bold text-white">
                Dynamic Graphic Engine
              </h1>
            </div>
            <h1 className="text-2xl font-semibold mb-6 text-white text-center">
              Login
            </h1>
            <form onSubmit={logindetails} method="POST" className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white"
                >
                  Email
                </label>
                <input
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    setFormErrors({ ...formErrors, email: "" });
                  }}
                  name="email"
                  className="mt-1 bg-[#2F3D53] mb-0 p-2 w-full text-white border rounded-md"
                />
                {formErrors.email && (
                  <p className="text-red-500 mb-0">{formErrors.email}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    setFormErrors({ ...formErrors, password: "" });
                  }}
                  name="password"
                  className="mt-1 bg-[#2F3D53] mb-0 p-2 w-full text-white border rounded-md"
                />
                {formErrors.password && (
                  <p className="text-red-500 mb-0">{formErrors.password}</p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full mt-2 bg-[#1A56DB] text-white p-2 rounded-md font-bold hover:bg-[#1a57dbb6] hover:text-white focus:outline-none focus:ring-gray-900 transition-colors duration-300"
                >
                  {/* Sign in */}
                  {isLoading ? ( 
                    <><Spinner aria-label="Default status example"  color="info" /> <span className="pl-3">Loading...</span></> 
                  ) : (
                    "Sign in"
                  )}
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-white text-center">
              <p>
                Create a new account{" "}
                <a
                  className="text-blue-500 hover:text-blue-700 font-bold hover:underline"
                  onClick={gosignup}
                >
                  Register
                </a>
              </p>
            </div>
            <div className="mt-4 text-sm text-white text-center">
              <p>
                <a
                  className="text-blue-500 hover:text-blue-700 font-bold hover:underline"
                  onClick={gosignup1}
                >
                  Forgot Password
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
