import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Navbar,
    Alignment,
    EditableText,
    Button,
    Menu,
    MenuItem,
    Popover,
    Position,
} from '@blueprintjs/core';
import styled from 'raeditor/utils/styled';
import { useProject } from '../project';
import { useNavigate } from "react-router-dom";
import { DownloadButton } from "./download-button";

const NavbarContainer = styled('div')`
    white-space: nowrap;
    padding: 0px 15px;

    @media screen and (max-width: 768px) {
        padding: 0px 15px;
    }

    @media screen and (max-width: 500px) {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100vw;
    }
`;

const NavInner = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: row;
        align-items: center;
    }
`;

const RightGroup = styled(Navbar.Group)`
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        /* Place download button last on larger screens */
        flex-direction: row-reverse;
    }
`;

const MyNavbar = observer(({ store }) => {
    const project = useProject();
    const [isSaving, setIsSaving] = useState(false);
    const [isTemplateUUID, setIsTemplateUUID] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (project.template_uuid) {
                setIsTemplateUUID(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [project.template_uuid]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await project.save();
        } catch (error) {
            console.error("Save failed:", error);
        }
        setIsSaving(false);
    };

    const handleAPI = async () => {
        await handleSave();
        navigate(`/templates-embed/${project.template_uuid}`);
    };

    const [errorMessage, setErrorMessage] = useState('');
    // const handleNameChange = (name) => {
    //     const nameCharLimit = 30;
    //     if (name.startsWith(' ')) {
    //         setErrorMessage('Template name cannot start with a space.');
    //     } else if (name.trim() === "") {
    //         window.project.name = name;
    //         setErrorMessage('Template name cannot be empty or just spaces.');
    //     } else if (name.length > nameCharLimit) {
    //         setErrorMessage(`Template name cannot exceed ${nameCharLimit} characters`);
    //     } else {
    //         window.project.name = name;
    //         window.project.requestSave();
    //         setErrorMessage('');
    //     }
    // };
    const handleNameChange = (name) => {
        const nameCharLimit = 30;
        if (name.startsWith(' ')) {
            setErrorMessage('Start without a space.');
        } else if (name.trim() === "") {
            window.project.name = name;
            setErrorMessage('Name can’t be blank.');
        } else if (name.length > nameCharLimit) {
            setErrorMessage(`Too long! Max ${nameCharLimit} chars`);
        } else {
            window.project.name = name;
            window.project.requestSave();
            setErrorMessage('');
        }
    };

    return (
        <NavbarContainer className="bp5-navbar">
            <NavInner>
                <Navbar.Group align={Alignment.LEFT}>
                    <div
                        style={{
                            paddingLeft: '10px',
                            maxWidth: '200px',
                        }}
                    >
                        <EditableText
                            value={window.project.name}
                            // value={window.project.name.slice(0, 30)} 
                            // value={window.project.name ? window.project.name.slice(0, 30) : ''} 
                            placeholder="Design name"
                            className="min-w-[150px] md:min-w-[250px] mr-6 text-sm font-medium text-gray-900 dark:text-white"
                            onChange={handleNameChange}
                        />
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </div>
                </Navbar.Group>

                <RightGroup align={Alignment.RIGHT}>
                    {/* Download Button - should appear before the hamburger menu on mobile */}
                    <div className="md:hidden me-2">
                        <DownloadButton store={store} />
                    </div>

                    {/* Dropdown for Save and API buttons on mobile */}
                    <Popover
                        content={
                            <Menu>
                                <MenuItem
                                    icon="floppy-disk"
                                    text={isSaving ? "Saving..." : "Save"}
                                    onClick={handleSave}
                                    disabled={isSaving || errorMessage !== ''}
                                />
                                <MenuItem
                                    icon="code"
                                    text="API Integration"
                                    onClick={handleAPI}
                                    disabled={!isTemplateUUID}
                                />
                            </Menu>
                        }
                        position={Position.BOTTOM_RIGHT}
                    >
                        
                        <Button
                            icon="more"
                            minimal
                            className="block md:hidden text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1"
                        />
                    </Popover>

                    {/* Save and API buttons for larger screens */}
                    <div className="hidden md:flex space-x-[2px]">
                        <Button
                            icon="floppy-disk"
                            text={isSaving ? "Saving..." : "Save"}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 me-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
                            intent="primary"
                            onClick={handleSave}
                            disabled={isSaving || errorMessage !== ''}
                        />
                        <Button
                            icon="code"
                            text="API Integration"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 me-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
                            intent="primary"
                            onClick={handleAPI}
                            disabled={!isTemplateUUID}
                        />
                    </div>

                    {/* Download Button for larger screens - add margin for spacing */}
                    <div className="hidden md:block me-[10px]">
                        <DownloadButton store={store} />
                    </div>
                </RightGroup>
            </NavInner>
        </NavbarContainer>
    );
});

export default MyNavbar;

