import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup } from '@blueprintjs/core';
import IosColorPalette from '@meronex/icons/ios/IosColorPalette';
import { ColorPicker } from 'raeditor/toolbar/color-picker';
import { ImagesGrid } from 'raeditor/side-panel/images-grid';
import styled from 'raeditor/utils/styled';
import { t } from 'raeditor/utils/l10n';
import { useInfiniteAPI } from 'raeditor/utils/use-api';
import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api/photos`;

const colors = [
    'white',
    'rgb(82, 113, 255)',
    'rgb(255, 145, 77)',
    'rgb(126, 217, 87)',
    'rgb(255, 222, 89)',
    'rgb(203, 108, 230)',
    'rgb(0, 0, 0, 0)',
];

const ColoredContainer = styled('div')`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(16, 22, 26, 0.3);
  cursor: pointer;
`;

const Colored = ({ children, color, ...rest }) => (
    <ColoredContainer
        {...rest}
        style={{
            ...rest.style,
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 8 8'%3E%3Cg fill='rgba(112, 112, 116, 1)' fill-opacity='1'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
        }}
    >
        <div style={{ width: '100%', height: '100%', background: color }}>{children}</div>
    </ColoredContainer>
);

export const BackgroundPanel = observer(({ store }) => {
    const {
        setQuery,
        loadMore,
        isReachingEnd,
        data,
        isLoading,
        error,
    } = useInfiniteAPI({
        defaultQuery: 'gradient',
        getAPI: ({ page, query }) => `${API_BASE_URL}/unsplash?query=${query}&page=${page}&per_page=50`,
    });

    const activePageBackground = store.activePage?.background || 'white';

    const handleColorChange = (color) => {
        store.activePage?.set({ background: color });
    };

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Color Picker and Preset Colors */}
            <div style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '10px' }}>
                <ColorPicker value={activePageBackground} onChange={handleColorChange} store={store}>
                    <Colored color={activePageBackground} style={{ fontSize: '30px' }}>
                        <IosColorPalette style={{ mixBlendMode: 'difference' }} />
                    </Colored>
                </ColorPicker>
                {colors.map((color) => (
                    <Colored
                        key={color}
                        color={color}
                        onClick={() => handleColorChange(color)}
                    />
                ))}
            </div>

            {/* Search Input */}
            <InputGroup
                leftIcon="search"
                placeholder={t('sidePanel.searchPlaceholder')}
                onChange={(e) => setQuery(e.target.value)}
                type="search"
                style={{ marginBottom: '20px' }}
            />

            {/* Unsplash Attribution */}
            <p style={{ textAlign: 'center' }}>
                Photos by{' '}
                <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
                    Unsplash
                </a>
            </p>

            {/* Images Grid */}
            <ImagesGrid
                images={data?.map((item) => item).flat().filter(Boolean)}
                isLoading={isLoading}
                getPreview={(item) => item.urls?.thumb}
                loadMore={!isReachingEnd && loadMore}
                onSelect={async (item) => {
                    const { urls, links } = item;
                    await axios.post(`${API_BASE_URL}/unsplash/download`, {
                        downloadLink: links.download_location,
                    });
                    store.activePage?.set({ background: urls.regular });
                }}
                error={error}
                getCredit={(item) => (
                    <span>
                        Photo by
                        <a
                            href={`https://unsplash.com/@${item.user.username}?utm_source=raeditor&utm_medium=referral`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
              {item.user.name}
            </a>{' '}
                        on{' '}
                        <a
                            href="https://unsplash.com/?utm_source=raeditor&utm_medium=referral"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
              Unsplash
            </a>
          </span>
                )}
            />
        </div>
    );
});

export default BackgroundPanel;
