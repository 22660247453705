import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Tooltip, Button } from "@blueprintjs/core";
import { FaDatabase } from "react-icons/fa";
import { GoDatabase } from "react-icons/go";

export const DataFieldButton = observer(({ store, element }) => {
    const [opacity, setOpacity] = useState(0.7);
    const [icon, setIcon] = useState(<GoDatabase />);

    if (!element) {
        element = store.selectedElements[0];
    }

    useEffect(() => {
        if (!element) return;

        updateButtonState(element.custom?.dataField, element.id);
    }, [element?.custom?.dataField, element?.id]);

    const updateButtonState = (dataField, id) => {
        const newOpacity = dataField ? 1 : 0.7;
        const newIcon = dataField ? <FaDatabase /> : <GoDatabase />;
        setOpacity(newOpacity);
        setIcon(newIcon);

        const buttons = document.querySelectorAll(`.dfb-${id}`);
        buttons.forEach(button => {
            button.style.opacity = newOpacity;
        });
    };

    const handleClick = () => {
        if (!element) return;
        let updatedCustom = { ...element.custom };
        updatedCustom = { ...updatedCustom, dataField: !element.custom?.dataField};
        element.set({ custom: updatedCustom });
        updateButtonState(element.custom?.dataField, element.id);
    };

    if (!element) return null;

    return (
        <Tooltip
            content="Data Field"
            position="bottom"
        >
            <Button
                icon={icon}
                minimal
                onClick={handleClick}
                className={`dfb-${element.id}`}
                style={{ padding: "5px", minWidth: "27px", opacity: opacity, marginLeft: "auto" }}
            />
        </Tooltip>
    );
});
