import React, { useState, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import { Sidebar } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, TextInput } from "flowbite-react";
import {
  defaultTemplatesGroup,
  createTemplate,
} from "../APIservice/apiService";
import { getDefaultTemplateById, saveDesign, getPreview } from "../api";
import axios from "axios";
import Cookies from "js-cookie";

const TempModel = ({ isOpen, onClose }) => {
  const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;

  const getAuthToken = () => Cookies.get("token");
  const { projectId } = useParams();

  const [activeItem, setActiveItem] = useState("1280x720"); // initial active item set to "custom"
  const [customactive, setCustomactive] = useState("1280x720"); // initial active item set to "custom"
  const [openModal, setOpenModal] = useState(isOpen);
  const [getModelData, setGetModelData] = useState({});
  const [sizeData, setSizeData] = useState([]);
  const [templateSizes, setTemplateSizes] = useState([]);
  const [showCustomItems, setShowCustomItems] = useState(false);
  const [customWidth, setCustomWidth] = useState("1280");
  const [customHeight, setCustomHeight] = useState("720");

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setActiveItem(item);
    if (item === "custom") {
      setShowCustomItems(true);
    } else {
      setShowCustomItems(false);
      setSizeData(getModelData[item]);
    }
    console.log("this is custom button", item);
    setSizeData(getModelData[item]);
  };

  const handleItemClick2 = (item) => {
    setCustomactive(item);
    setCustomWidth(item.split("x")[0]);
    setCustomHeight(item.split("x")[1]);
  };

  const handlecustomwidth = (event) => {
    console.log("custom width", event.target.value);
    setCustomWidth(event.target.value);
  };
  const handlecustomHeight = (event) => {
    console.log("custom width", event.target.value);
    setCustomHeight(event.target.value);
  };

  const fetchModel = async () => {
    try {
      const response = await defaultTemplatesGroup();
      setTemplateSizes(Object.keys(response));
      setGetModelData(response);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }
  };

  const createTemplate = async () => {
    try {
      const response = await createTemplate();
      console.log(response);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }
  };

  useEffect(() => {
    setOpenModal(isOpen);
    fetchModel();
  }, [isOpen]);

  useEffect(() => {
    if (getModelData[activeItem]) {
      setSizeData(getModelData[activeItem]);
    }
  }, [getModelData, activeItem]);

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  // const handleImageClick = (id) => {
  //     localStorage.setItem('templateId', id);
  //     console.log(id)
  //     navigate(`/projects/${projectId}/template/`);
  // };
  // const handleImageClick = async (template) => {
  //     // localStorage.setItem('templateId', id);
  //     console.log(template,'click id');

  //     // createTemplate(template)

  // // Parse the JSON content
  // // const jsonData = await jsonResponse?.storeJSON.json();
  // // console.log(jsonData,"data is Here")
  // const apiRequest = async ({url, method, headers = {}, params = {}, data = null}) => {
  //     const token = getAuthToken();
  //     if (!token) {
  //         throw new Error("Authorization token is missing");
  //     }

  //     const authHeaders = { 'Authorization': `Bearer ${token}` };
  //     const request = {
  //         method,
  //         url: `${API_BASE_URL}${url}`,
  //         headers: { ...authHeaders, ...headers },
  //         params,
  //     };

  //     if (data) {
  //         request.data = data;
  //     }

  //     const response = await axios(request);
  //     return response.data;
  // };

  // try {
  //     const response = await getDefaultTemplateById({id:template?.id});
  //     const res = response.storeJSON
  //     const saveDesign =  () => {
  //         const formData = new FormData();

  //         formData.append('template_name', 'undefined');
  //         formData.append('projectId', projectId);
  //         formData.append('template_data', JSON.stringify(response.storeJSON));
  //         formData.append('thumbnail', template.preview_path);

  //         return apiRequest({
  //             url: '/templates',
  //             method: 'POST',
  //             data: formData,
  //             headers: { 'Content-Type': 'multipart/form-data' }
  //         });
  //     };
  // } catch (error) {
  //     console.error("Fetching model failed:", error);
  // }
  //     // navigate(`/projects/${projectId}/template/`);
  // };

  const apiRequest = async ({
    url,
    method,
    headers = {},
    params = {},
    data = null,
  }) => {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authorization token is missing");
    }

    const authHeaders = { Authorization: `Bearer ${token}` };
    const request = {
      method,
      url: `${API_BASE_URL}${url}`,
      headers: { ...authHeaders, ...headers },
      params,
    };

    if (data) {
      request.data = data;
    }

    const response = await axios(request);
    return response.data;
  };

  const handleImageClick = async (template) => {
    // console.log(template, "click id");

    //   const fetchThumbnailAsBinary = async (url) => {
    //     const response = await fetch(url, { mode: "cors" });
    //     const arrayBuffer = await response.arrayBuffer();
    //     const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Adjust the type based on the image format
    //     return blob;
    // };

    const fetchThumbnailAsBinary = async (url) => {
      try {
        // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        //  const url1 = `https://cors-anywhere.herokuapp.com/?${encodeURIComponent(url)}`;
        // const url1 = `${encodeURIComponent(url)}`;
        // const response = await fetch(url1, { mode: "cors" });

        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const urlWithProxy = `${url}`;
        const response = await fetch(urlWithProxy, { mode: "no-cors" });

        // Check if the response is OK
        // if (!response.ok) {
        //   throw new Error(`Failed to fetch image. Status: ${response.status}`);
        // }

        // Convert the response to an arrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Get the MIME type from the response headers
        const contentType = response.headers.get("content-type");
        const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
        return blob;
      } catch (error) {
        console.error("Error fetching or converting image:", error);
      }
    };

    try {
      const response = await getDefaultTemplateById({ id: template?.id });
      // console.log(response,"HERE IT WAS")
      const res = response.storeJSON;

      const saveDesign = async () => {
        const formData = new FormData();

        formData.append("template_name", "undefined");
        formData.append("projectId", projectId);
        formData.append("template_data", JSON.stringify(res));
        try {
          const thumbnailBlob = await fetchThumbnailAsBinary(
            template.preview_path
          );
          console.log(thumbnailBlob, "BLOB IS HERE");
          formData.append("thumbnail", thumbnailBlob); // Assuming the image is a PNG
        } catch (error) {
          console.error("Failed to fetch thumbnail:", error);
          return;
        }

        try {
          const response = await apiRequest({
            url: "/templates",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          // console.log('Design saved successfully');
          return response; // Return the response from apiRequest
        } catch (error) {
          // console.error('Saving design failed:', error);
          return null; // Return null or an appropriate value in case of an error
        }
      };

      // const callAnotherApi = async (savedTemplateId) => {
      //   const formData = new FormData();

      //   // Assuming the same data needs to be sent, modify as needed
      //   formData.append("template_name", "undefined");
      //   formData.append("projectId", projectId);
      //   formData.append("template_data", JSON.stringify(res));
      //   formData.append("template_id", savedTemplateId);
      //   try {
      //     const thumbnailBlob = await fetchThumbnailAsBinary(
      //       template.preview_path
      //     );
      //     formData.append("thumbnail", thumbnailBlob); // Assuming the image is a PNG
      //   } catch (error) {
      //     console.error("Failed to fetch thumbnail:", error);
      //     return;
      //   }
      //   // You can also pass any additional data in this API call as needed

      //   try {
      //     const anotherApiResponse = await apiRequest({
      //       url: `/templates`, // Adjust the endpoint as needed
      //       method: "POST",
      //       data: formData,
      //       headers: { "Content-Type": "multipart/form-data" },
      //     });

      //     // console.log("Another API Response:", anotherApiResponse);
      //     localStorage.setItem('templateId', saveResponse.id);
      //   navigate(`/projects/${projectId}/template/${saveResponse.id}`);

      //   } catch (error) {
      //     console.error("Another API failed:", error);
      //   }
      // };

      const saveResponse = await saveDesign();

      // if (saveResponse && saveResponse.id) {
      //   console.log("Save Design Response:", saveResponse);
      //   // Navigate to the saved design's page
      //   // Call the second API with the saved template ID
      //   await callAnotherApi(saveResponse.id);
      // } else {
      //   console.error("Save Design failed, response is null.");
      // }

      console.log("Save Design Response:", saveResponse);
      navigate(`/projects/${projectId}/template/${saveResponse.id}`);
    } catch (error) {
      console.error("Fetching model failed:", error);
    }

    // Navigate to a different route
    // navigate(`/projects/${projectId}/template/`);
  };

  const handleCustomClick = (width, height) => {
    const jsonStore = {
      width: parseInt(width),
      height: parseInt(height),
      fonts: [],
      pages: [
        {
          id: "S5YfeK-vMM",
          children: [],
          width: "auto",
          height: "auto",
          background: "white",
          bleed: 0,
          duration: 5000,
        },
      ],
      unit: "px",
      dpi: 72,
    };

    const jsonString = JSON.stringify(jsonStore);
    const blob = new Blob([jsonString], { type: "application/json" });
    console.log(blob, "here is blob");

    const saveDesign = async () => {
      const formData = new FormData();

      formData.append("template_name", "undefined");
      formData.append("projectId", projectId);
      formData.append("template_data", JSON.stringify(jsonStore));
      formData.append("thumbnail", blob);

      try {
        const response = await apiRequest({
          url: "/templates",
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        // console.log(response); // Log the response from apiRequest
        navigate(`/projects/${projectId}/template/${response.id}`);
      } catch (error) {
        console.error("Saving design failed:", error);
        return null; // Return null or an appropriate value in case of an error
      }
    };

    // Call the saveDesign function
    saveDesign();

    // Navigate to a different route if needed
    // navigate(`/projects/${projectId}/template/`);
  };

  function generateViewBox(customWidth, customHeight) {
    return `0 0 ${customWidth} ${customHeight}`;
    return;
  }

  console.log(sizeData);
  return (
    <>
      {/* <Modal show={openModal} position='center'  size="6xl" popup onClose={() => handleClose()}>  
        <Modal.Header />
        <Modal.Body>
          <div className="flex">
            <div>
              <div className="w-full flex justify-center items-center rounded-t-lg h-[55px] bg-[#1F2937] sticky top-[2px] z-9">
                <ButtonGroup outline className="mt-4">
                  <Button
                    color="gray"
                    onClick={() => handleItemClick("1280x720")}
                    className={`w-[60px] md:w-20 ${activeItem === "1280x720" ? "active" : ""}`}
                  >
                    Presets
                  </Button>
                  <Button
                    color="gray"
                    onClick={() => handleItemClick("custom")}
                    className={`w-[70px] md:w-20 ${activeItem === "custom" ? "active" : ""}`}
                  >
                    Customs
                  </Button>
                </ButtonGroup>
              </div>
              <Sidebar
                aria-label="Sidebar with multi-level dropdown example"
                className="overflow-y-auto h-[442px] md:h-[600px] w-36  md:w-64"
              >
                <Sidebar.Items>
                  <Sidebar.ItemGroup>
                    {!showCustomItems &&
                      templateSizes.map((size) => (
                        <Sidebar.Item
                          key={size} // Adding a unique key
                          href="#"
                          onClick={() => handleItemClick(size)}
                          className={
                            activeItem === size
                              ? "bg-blue-500 text-white"
                              : "text-gray-700"
                          }
                        >
                          {size}
                        </Sidebar.Item>
                      ))}
                    {showCustomItems &&
                      templateSizes.map((size) => (
                        <Sidebar.Item
                          key={size} // Adding a unique key
                          href="#"
                          onClick={() => handleItemClick2(size)}
                          className={
                            customactive === size
                              ? "bg-blue-500 text-white"
                              : "text-gray-700"
                          }
                        >
                          {size}
                        </Sidebar.Item>
                      ))}
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </Sidebar>
            </div>
            {activeItem !== "custom" && (
              <div className="w-full p-4 border-2 border-gray-200  rounded-lg dark:border-gray-700 h-[499px] md:h-[657px] bg-[#1F2937]">
                <div className="w-full h-full overflow-y-scroll bg-[#1F2937]">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2">
                    {sizeData &&
                      sizeData.map((template, index) => {
                        return (
                          <div
                            key={template.id}
                            onClick={() => handleImageClick(template)}
                            className="template rounded cursor-pointer hover:scale-[1.05]"
                          >
                            <img
                              src={template.preview_path}
                              alt={`Template ${index + 1}`}
                              width={template.width}
                              height={template.height}
                              className="rounded"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {activeItem === "custom" && (
              <div className="w-full ml-[1px] h-[494px] mt-[2px] top-[5px] md:h-[653px] md:mt-[2px] bg-[#1F2937] rounded-lg flex justify-center items-center overflow-hidden flex-col">
                <div className="w-full p-4 h-full flex justify-center items-center overflow-hidden">
                  <svg
                    className="max-w-[95%] max-h-[95%] border-2 border-gray-500 dark:border-none rounded-lg"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    viewBox={generateViewBox(customWidth, customHeight)}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      fill="currentColor"
                      className="text-gray-600 dark:text-white"
                      strokeWidth="1"
                      width={customWidth}
                      height={customHeight}
                      rx="20"
                      ry="20"
                    ></rect>
                  </svg>
                </div>
                <div className="flex justify-center sm:justify-end w-full py-3 px-5 bg-[#1F2937]">
                  <button
                    onClick={() => handleCustomClick(customWidth, customHeight)}
                    className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-1.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                  >
                    Create
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal> */}

<Modal show={openModal} size={'6xl'} onClose={() => handleClose()} className=' '>
  <Modal.Header>
    <ButtonGroup outline className="flex justify-center space-x-0">
      <Button
        // color="gray"
        color={activeItem !== "custom" ? "blue" : "gray"}
        onClick={() => handleItemClick("1280x720")}
        className={`w-[60px] md:w-20 ${activeItem === "1280x720" ? "active" : ""}`}
      >
        Presets
      </Button>
      <Button
        // color="gray"
        color={activeItem === "custom" ? "blue" : "gray"}
        onClick={() => handleItemClick("custom")}
        className={`w-[70px] md:w-20 ${activeItem === "custom" ? "active" : ""}`}
      >
        Customs
      </Button>
    </ButtonGroup>
  </Modal.Header>
  
  <Modal.Body className=" overflow-y-auto">
    {/* Flex Container for Responsive Layout */}
    <div className="flex flex-row md:flex-nowrap p-0 h-auto md:h-[600px]">

      {/* Left Sidebar Content for Mobile and Desktop */}
      <div className="w-full md:w-[28%] h-auto md:h-full">
        <Sidebar
          aria-label="Sidebar with multi-level dropdown example"
          className="overflow-y-auto h-[300px] md:h-full w-full"
        >
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {!showCustomItems &&
                templateSizes.map((size) => (
                  <Sidebar.Item
                    key={size}
                    href="#"
                    onClick={() => handleItemClick(size)}
                    className={activeItem === size ? "bg-blue-500 text-white" : "text-gray-700"}
                  >
                    {size}
                  </Sidebar.Item>
                ))}
              {showCustomItems &&
                templateSizes.map((size) => (
                  <Sidebar.Item
                    key={size}
                    href="#"
                    onClick={() => handleItemClick2(size)}
                    className={customactive === size ? "bg-blue-500 text-white" : "text-gray-700"}
                  >
                    {size}
                  </Sidebar.Item>
                ))}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>

      {/* Right Side Content */}
      <div className="w-full md:w-[72%] h-auto md:h-full">
        {activeItem !== "custom" && (
          <div className="w-full p-4  border-gray-200 rounded-[0.25rem] dark:border-gray-700 h-[300px] md:h-full bg-[#1F2937]">
            <div className="w-full h-full overflow-y-auto bg-[#1F2937]">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
                {sizeData &&
                  sizeData.map((template, index) => (
                    <div
                      key={template.id}
                      onClick={() => handleImageClick(template)}
                      className="template rounded cursor-pointer hover:scale-[1.05] h-auto"
                    >
                      <img
                        src={template.preview_path}
                        alt={`Template ${index + 1}`}
                        width={template.width}
                        height={template.height}
                        className="rounded w-full h-full object-cover"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {activeItem === "custom" && (
          <div className="w-full h-[300px] md:h-full bg-[#1F2937] rounded-[0.25rem] flex justify-center items-center overflow-y-auto flex-col">
            <div className="w-full p-4 h-full flex justify-center items-center ">
              <svg
                className="max-w-[95%] max-h-[95%]"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
                viewBox={generateViewBox(customWidth, customHeight)}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  fill="currentColor"
                  className="text-gray-600 dark:text-white"
                  strokeWidth="1"
                  width={customWidth}
                  height={customHeight}
                  rx=""
                  ry=""
                ></rect>
              </svg>
            </div>
            {/* <div className="flex justify-center sm:justify-end w-full py-3 px-5 bg-[#1F2937]">
              <button
                onClick={() => handleCustomClick(customWidth, customHeight)}
                className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-1.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              >
                Create
              </button>
            </div> */}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        {activeItem === "custom" && (
          <Modal.Footer className="flex justify-end p-[0.5rem] mr-5 mb-2 md:mb-0">
            {/* <Button
           className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-1.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" >
          Create
          </Button> */}
            <span className="hidden md:block text-white text-base ">
              Custom Width and Height
            </span>
            <input
              type="text"
              className="p-2 border w-20 h-[37px] md:w-20  border-gray-300 rounded-lg "
              value={customWidth}
              onChange={handlecustomwidth}
            />
            <span className="text-white text-base">x</span>
            <input
              type="text"
              className="p-2 border w-20 h-[37px] md:w-20  border-gray-300 rounded-lg "
              value={customHeight}
              onChange={handlecustomHeight}
            />

            <button
              onClick={() => handleCustomClick(customWidth, customHeight)}
              className="p-[25px] py-2.5 text-xs md:text-sm font-medium rounded-full inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              Create
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default TempModel;
