import React from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'raeditor/side-panel';
import FaStar from '@meronex/icons/fa/FaStar';
import { Button, InputGroup, HTMLSelect, NumericInput } from '@blueprintjs/core';
import ColorPicker from "raeditor/toolbar/color-picker";

const starPathData = "M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z";

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

// Function to generate SVG content for the rating stars
const generateStarsSVG = (starSize, updatedCustom, elementWidth) => {
    const { value, scale, spacing, colorFull, colorEmpty } = updatedCustom;
    const stars = Array.from({ length: parseInt(scale, 10) }, (_, i) => {
        const fillColor = i < Math.floor(value) ? colorFull : colorEmpty;
        const halfStar = value - Math.floor(value) >= 0.5 && i === Math.floor(value);

        return halfStar
            ? `<svg x="${i * (starSize + spacing)}" y="0" width="${starSize}" height="${starSize}" viewBox="0 0 50 50">
                <defs>
                    <linearGradient id="half-fill">
                        <stop offset="50%" stop-color="${colorFull}" />
                        <stop offset="50%" stop-color="${colorEmpty}" stop-opacity="1" />
                    </linearGradient>
                </defs>
                <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" fill="url(#half-fill)" />
               </svg>`
            : `<svg x="${i * (starSize + spacing)}" y="0" width="${starSize}" height="${starSize}" viewBox="0 0 50 50">
                <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" fill="${fillColor}" />
               </svg>`;
    }).join('');

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${elementWidth}" height="${starSize}">${stars}</svg>`;
};

// Define the new custom section
export const RatingStarSection = {
    name: 'rating-stars',
    Tab: (props) => (
        <SectionTab name="Rating Stars" {...props}>
            <FaStar className='w-5 h-5'/>
        </SectionTab>
    ),
    Panel: observer(({ store }) => {
        const [value, setValue] = React.useState(3);
        const [scale, setScale] = React.useState('5');
        const [spacing, setSpacing] = React.useState(0);
        const [colorFull, setColorFull] = React.useState('#FFD700');
        const [colorEmpty, setColorEmpty] = React.useState('#DDDDDD');

        return (
            <div>
                <div className="px-3 py-1 flex items-center w-full justify-between">
                    <div className="mr-2">Default Value:</div>
                    <InputGroup
                        value={value}
                        onChange={(e) => setValue(parseFloat(e.target.value))}
                        type="number"
                        step="0.5"
                        style={{width:'100px'}}
                    />
                </div>
                <div className="px-3 py-1 flex items-center w-full justify-between">
                    <div className="mr-2">Scale:</div>
                    <HTMLSelect
                     style={{
                       
                        appearance: "none", // Hides the default arrow
                        WebkitAppearance: "none", // For Safari and Chrome
                        MozAppearance: "none", // For Firefox
                        background: "none", // Removes the background that may contain the arrow
                      
                       // Optional: Adjust padding as needed
                      }} // Added styles to remove arrows
                        value={scale}
                        onChange={(e) => setScale(parseInt(e.target.value))}
                    >
                        <option value="5">0-5</option>
                        <option value="10">0-10</option>
                    </HTMLSelect>
                </div>
                <div className="px-3 py-1 flex items-center w-full justify-between">
                    <div className="mr-2">Spacing:</div>
                    <InputGroup
                        value={spacing}
                        onChange={(e) => setSpacing(parseInt(e.target.value))}
                        type="number"
                        min="0"
                        step="10"
                        style={{width:'100px'}}
                    />
                    {/*<NumericInput*/}
                    {/*    value={spacing}*/}
                    {/*    onValueChange={(valueAsNumber) => setSpacing(valueAsNumber)}*/}
                    {/*    min={0}*/}
                    {/*    stepSize={10}*/}
                    {/*    style={{ width: '100px' }}*/}
                    {/*/>*/}
                </div>
                <div className="px-3 py-1 flex items-center w-full justify-between">
                    <div className="mr-2">Color Full:</div>
                    <ColorPicker
                        value={colorFull}
                        onChange={(colorFull) => setColorFull(colorFull)}
                        store={store}
                    />
                </div>
                <div className="px-3 py-1 flex items-center w-full justify-between">
                    <div className="mr-2">Color Empty:</div>
                    <ColorPicker
                        value={colorEmpty}
                        onChange={(colorEmpty) => setColorEmpty(colorEmpty)}
                        store={store}
                    />
                </div>
                <Button
                    className="mt-2 w-fit"
                    fill
                    intent="primary"
                    onClick={async () => {
                        const starSize = 50;
                        const elementWidth = (starSize + spacing) * scale - spacing;
                        const svgContent = generateStarsSVG(starSize,{ value, scale, spacing, colorFull, colorEmpty }, elementWidth);
                        store.activePage.addElement({
                            type: 'svg',
                            name: `ratingStar-${generateRandomString(8)}`,
                            x: 50,
                            y: 50,
                            width: elementWidth,
                            height: starSize,
                            src: `data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}`,
                            custom: { value, scale, spacing, colorFull, colorEmpty, isRatingStar: true },
                        });
                        store.openSidePanel('');
                    }}
                >
                    Add Rating Stars
                </Button>
            </div>
        );
    }),
};
