import React from 'react';
import { observer } from "mobx-react-lite";
import { Card, Icon, EditableText, Button } from "@blueprintjs/core";
import { t } from "raeditor/utils/l10n";
import { isMobile } from "raeditor/utils/screen";
import { ReactSortable } from "react-sortablejs";
import { removeTags } from "raeditor/utils/text";
import {SectionTab} from "raeditor/side-panel";
import {FaDatabase, FaLayerGroup} from "react-icons/fa";
import {DataFieldButton} from "./data-field-button";

const SortItem = observer(({ element, store }) => {
    const isSelected = store.selectedElements.indexOf(element) >= 0;
    const [isEditing, setIsEditing] = React.useState(false);
    const stopEditing = () => setIsEditing(false);
    const elementName = isEditing ? element.name : element.name || removeTags(element.text) || `#${element.id}`;

    return (
        <Card
            onMouseDown={e => {
                const selectedElements = e.ctrlKey || e.metaKey || e.shiftKey ? [...store.selectedElementsIds] : [];
                if (selectedElements.indexOf(element.id) < 0) {
                    selectedElements.push(element.id);
                }
                store.selectElements(selectedElements);
            }}
            style={{
                padding: "5px",
                margin: "0px 1px 5px 1px",
                backgroundColor: isSelected ? "rgb(0, 161, 255, 0.2)" : "",
                display: element.selectable || store.role === "admin" ? "auto" : "none"
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", lineHeight: "30px" }}>
                    <div
                        className="drag-handle"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "move", height: "30px" }}
                    >
                        <Icon icon="drag-handle-vertical" />
                    </div>
                    <div style={{ padding: "0 7px", opacity: 0.5, width: "55px" }}>
                        {t(`sidePanel.layerTypes.${element.type}`)}
                    </div>
                    <div style={{ maxWidth: "135px" }}>
                        <EditableText
                            minWidth={130}
                            placeholder={t("sidePanel.namePlaceholder")}
                            value={elementName}
                            maxLines={1}
                            onEdit={() => setIsEditing(true)}
                            onCancel={stopEditing}
                            onConfirm={stopEditing}
                            onChange={name => element.set({ name })}
                        />
                    </div>
                </div>
                <div>
                    <DataFieldButton store={store} element={element} />
                    <Button
                        style={{ padding: "5px", minWidth: "27px" }}
                        minimal
                        icon={element.visible ? "eye-open" : "eye-off"}
                        onClick={() => element.set({ visible: !element.visible })}
                    />
                    <Button
                        style={{ padding: "5px", minWidth: "27px" }}
                        minimal
                        icon={element.locked ? "lock" : "unlock"}
                        onClick={() => element.set({ draggable: element.locked, contentEditable: element.locked, styleEditable: element.locked, resizable: element.locked })}
                    />
                    <Button
                        icon="trash"
                        minimal
                        disabled={!element.removable}
                        onClick={() => store.deleteElements([element.id])}
                        style={{ marginLeft: "auto", padding: "5px", minWidth: "27px" }}
                    />
                </div>
            </div>
        </Card>
    );
});

export const LayersPanel = observer(({ store }) => {
    const activePage = store.activePage;
    const layerItems = activePage?.children.map(child => ({ id: child.id })) || [];
    layerItems.reverse();
    const isMobileView = isMobile();

    return (
        <div style={{ height: "100%", overflow: "auto" }}>
            <div style={{ height: "40px", paddingTop: "5px" }}>
                {t("sidePanel.layersTip")}
            </div>
            <div>
                {layerItems.length === 0 ? (
                    <div>{t("sidePanel.noLayers")}</div>
                ) : (
                    <ReactSortable
                        list={layerItems}
                        setList={sortedItems => {
                            sortedItems.forEach(({ id }, index) => {
                                const reversedIndex = sortedItems.length - index - 1;
                                const element = store.getElementById(id);
                                if (activePage?.children.indexOf(element) !== reversedIndex) {
                                    activePage?.setElementZIndex(element.id, reversedIndex);
                                }
                            });
                        }}
                        direction="horizontal"
                        handle={isMobileView ? ".drag-handle" : undefined}
                    >
                        {layerItems.map(({ id }) => {
                            const element = store.getElementById(id);
                            return <SortItem key={id} element={element} store={store} />;
                        })}
                    </ReactSortable>
                )}
            </div>
        </div>
    );
});

export const LayersSection = {
    name: 'layers',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.layers')} {...props}>
            <FaLayerGroup className='w-5 h-5' />
        </SectionTab>
    )),
    Panel: LayersPanel,
};