// UserContext.js
import React, { createContext, useState } from 'react';
import Cookies from "js-cookie";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const UserSession = Cookies.get("user");
    // const UserSession = sessionStorage.getItem('user');
    const user = UserSession ? JSON.parse(UserSession) : {};
    const [UserName, setUser] = useState({
        name: user.name,
        email: user.email,
        credits: user.credits,
        plan: user.plan,
    });

    // console.log(UserName)
    const updateUser = (userData) => {
        setUser(userData);
    };

    return (
        <UserContext.Provider value={{ UserName, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};
