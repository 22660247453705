import React, { useState } from "react";
import { requestResetPassword } from '../APIservice/apiService';
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";


const Forgot = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
 
  const [formData, setFormData] = useState({
    email: "",
    host: "https://skinlay.in"
  });

  const [formErrors, setFormErrors] = useState({});

  const gosignup = () => navigate("/login");

  const sendEmail = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.email)
      errors.email = "Email is required";


    if(Object.keys(errors).length > 0){
      setFormErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const response = await requestResetPassword(formData.email,formData.host);
      setIsLoading(false);
      if (response.name) {
        navigate('/dashboard');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Login failed:', error);
    }
  };
  return (
    <>
      <div className="flex h-screen">
        <div className="hidden p-5 hide-on-specific-dimensions lg:flex items-center justify-center flex-1 bg-[#1F2937]  text-black">
        <img
              className="h-full rounded-lg w-full"
              src="https://images.pexels.com/photos/12786601/pexels-photo-12786601.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt=""
            />
        </div>

        <div className="w-full bg-[#111827] hide-on-specific-dimensions-width lg:w-1/2 flex items-center justify-center">

          <div className="max-w-lg bg-[#1F2937] rounded-xl w-full p-6">
            <div className="flex items-center mb-6">
              <img
                  src="https://cdn.pixabay.com/photo/2017/02/15/00/48/logo-2067396_640.png" // Replace with your logo URL
                  alt="Logo"
                  className="h-14" // Adjust the size of the logo
              />
              <h1 className=" ml-3 text-3xl font-bold text-white">Dynamic Graphic Engine</h1>
            </div>
            <h1 className="text-2xl font-semibold mb-6 text-white text-center">
              Forgot Password
            </h1>
            <form onSubmit={sendEmail} method="POST" className="space-y-4">
              <div>
                <label
                    for="email"
                    className="block text-sm  font-medium text-white"
                >
                  Email
                </label>
                <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({...formData, email: e.target.value});
                      setFormErrors({...formErrors, email: ""}); // Clear the error message for email
                    }}
                    name="email"
                    className="mt-1 bg-[#2F3D53] mb-0 p-2 w-full text-white border rounded-md"
                />
                {formErrors.email && <p className="text-red-500 mb-0">{formErrors.email}</p>}
              </div>

              <div>
                <button
                    type="submit"
                  className="w-full mt-2 bg-[#1A56DB] text-white p-2 rounded-md font-bold hover:bg-[#1a57dbb6] hover:text-white focus:outline-none focus:ring-gray-900 transition-colors duration-300"
                    
                >
                  
                  {isLoading ? ( 
                    <><Spinner aria-label="Default status example"  color="info" /> <span className="pl-3">Loading...</span></> 
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-white text-center">
              <p>
                Go to {" "}
                <a
                    //   href=""
                    className=" text-blue-500 hover:text-blue-700 font-bold hover:underline"
                    onClick={gosignup}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
