import api from "./apiHelper";
import { toast } from "react-toastify";

import Cookies from "js-cookie";
import { initSessionManagement } from "../sessionManager";
// Define your routes
const routes = {
  login: "/auth/login",
  register: "/auth/register",
  resetRequest: "/auth/request-password-reset",
  resetPassword: "auth/reset-password",
  updateProfile: "/user/profile",
  changePassword: "/user/password",
  generateToken: "/auth/generate",
  projects: "/projects",
  upload: "/photos/upload",
  templates: "/templates",
  default_templates: "/default-templates",
  get_Profile: "/user/getUser",
  createTemplate: "/templates",
  getUserCreditsAndTemplates: "/user/getUserCreditsAndTemplates",
};

const SESSION_TIMEOUT_DURATION = 7 * 24 * 60 * 60 * 1000;

const logout = () => {
  // sessionStorage.removeItem('token');
  // sessionStorage.removeItem('user');
  Cookies.remove("token"); // Removes the 'token' cookie
  Cookies.remove("user");
  sessionStorage.removeItem("savedImages");
  sessionStorage.clear();
  toast.info("Logged out due to inactivity.");
  window.location.href = "/login";
};

const initializeSessionManagement = () => {
  // const token = sessionStorage.getItem('token');
  const token = Cookies.get("token");

  if (token) {
    initSessionManagement(SESSION_TIMEOUT_DURATION, logout);
  }
};

export const login = async (email, password) => {
  try {
    const response = await api.post(routes.login, { email, password });
    if (response.data.token) {
      // localStorage.setItem('token', response.data.token);
      // localStorage.setItem('user', JSON.stringify(response.data));
      Cookies.set("token", response.data.token, { expires: 7 });
      Cookies.set("user", JSON.stringify(response.data), { expires: 7 });
      // sessionStorage.setItem('token', response.data.token);
      // sessionStorage.setItem('user', JSON.stringify(response.data));
      toast.success("Login successfully");
      initializeSessionManagement();
    } else {
      toast.error(response.data.error);
    }
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      toast.error("Incorrect email or password. Please try again.");
      logout();
    }
    toast.error("Login failed");
    throw error;
  }
};

export const register = async (name, email, password) => {
  try {
    const response = await api.post(routes.register, { name, email, password });
    if (response.data.token) {
      // localStorage.setItem('token', response.data.token);
      // localStorage.setItem('user', JSON.stringify(response.data));
      Cookies.set("token", response.data.token, { expires: 7 });
      Cookies.set("user", JSON.stringify(response.data), { expires: 7 });
      // sessionStorage.setItem('token', response.data.token);
      // sessionStorage.setItem('user', JSON.stringify(response.data));
      toast.success("Registration successful");
      initializeSessionManagement();
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error("Registration failed");
    throw error;
  }
};

export const requestResetPassword = async (email, host) => {
  try {
    const response = await api.post(routes.resetRequest, { email, host });
    if (response.data.message) {
      toast.success("Email Sent Successfully");
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error("Internal Server Error");
    throw error;
  }
};

export const resetPassword1 = async (confirmPassword, password, token) => {
  try {
    const response = await api.post(routes.resetPassword, {
      password,
      confirmPassword,
      token,
    });
    if (response.data) {
      toast.success("Password Reset Successfully");
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error(error.response.data.error);
    throw error;
  }
};

export const updateProfile = async (name, email) => {
  try {
    const response = await api.put(routes.updateProfile, { name, email });
    if (response.data) {
      toast.success("Profile Updated Successfully");
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error(error.response.data.error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await api.get(routes.get_Profile);
    if (response.data) {
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error(error.response.data.error);
    throw error;
  }
};

export const updatePassword = async (oldPassword, newPassword,host) => {
  try {
    const response = await api.put(routes.changePassword, {
      oldPassword,
      newPassword,
      host
    });
    if (response.data) {
      toast.success("Password Updated Successfully");
      Cookies.set("token", response.data.token, { expires: 7 });
    } else {
      toast.error(response.data.error);
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error(error.response.data.error);
    throw error;
  }
};

export const generateCode = async () => {
  try {
    const response = await api.post(routes.generateToken);
    return response.data.token;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    toast.error("Code generation failed");
    throw error;
  }
};
let toastId = null;
export const addProject = async (name, logo="") => {
  try {
    const response = await api.post(routes.projects, { name, logo });
    if (response.data) {
      if(toastId !== null) {
        toast.dismiss(toastId); // dismiss previous toast
      }
      toastId = toast.success('Project added successfully'); // keep track of newly created toast
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      logout(); // Call logout on 401 status
    }
    throw error;
  }
};

export const cloneProject = async (id) => {
  try {
    const response = await api.post(`${routes.projects}/clone-project/${id}`);
    if (response.data) {
      if (toastId !== null) {
        toast.dismiss(toastId); // dismiss previous toast
      }
      toastId = toast.success("Project Cloned successfully"); // keep track of newly created toast
    }
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    throw error;
  }
};

export const getProject = async () => {
  try {
    const response = await api.get(routes.projects);
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    throw error;
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await api.delete(`${routes.projects}/${id}`);
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    throw error;
  }
};

export const editProject = async (id, name, logo) => {
  try {
    const response = await api.put(`${routes.projects}/${id}`, { name, logo });
    if (response.data) {
      toast.success("Project Updated successfully");
    }
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout();
    }
    throw error;
  }
};

export const uploadImage = async (imageFile) => {
  try {
    const formData = new FormData();
    formData.append("file", imageFile);
    const response = await api.post(routes.upload, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw error;
  }
};

export const getTemplates = async (id) => {
  try {
    const response = await api.get(`${routes.projects}/${id}`);
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw error;
  }
};

export const deleteTemplate = async (id) => {
  try {
    const response = await api.delete(`${routes.templates}/${id}`);
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw error;
  }
};

export const cloneTemplate = async (id) => {
  try {
    const response = await api.post(`${routes.templates}/clone-template/${id}`);
    return response.data;
  } catch (error) {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw error;
  }
};

export const defaultTemplatesGroup = async () => {
  try {
    const response = await api.get(`${routes.default_templates}/group`);
    return response.data;
  } catch (e) {
    if (
      (e.response && e.response.status === 401) ||
      e.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw e;
  }
};

export const createTemplate = async (data) => {
  try {
    const response = await api.get(`${routes.createTemplate}`, data);
    return response.data;
  } catch (e) {
    if (
      (e.response && e.response.status === 401) ||
      e.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw e;
  }
};

export const getUserCreditsAndTemplates = async () => {
  try {
    const response = await api.get(`${routes.getUserCreditsAndTemplates}`);
    return response.data;
  } catch (e) {
    if (
      (e.response && e.response.status === 401) ||
      e.response.status === 403
    ) {
      logout(); // Call logout on 401 status
    }
    throw e;
  }
};
