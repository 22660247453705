import React, { useEffect, useRef, useState } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { isAlive } from 'mobx-state-tree';
import styled from 'raeditor/utils/styled';
import { t } from 'raeditor/utils/l10n';
import { useInfiniteAPI } from 'raeditor/utils/use-api';
import { getImageSize } from 'raeditor/utils/image';
import FaPhotoVideo from '@meronex/icons/fa/FaPhotoVideo';
import { ImagesGrid } from 'raeditor/side-panel/images-grid';
import { getCrop } from 'raeditor/utils/image';
import {SectionTab} from "raeditor/side-panel";
import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api/photos`;

const PhotosContainer = styled('div')`
    height: 100%;
    overflow: hidden;
`;

const UnsplashPanel = observer(({ store, query }) => {
    const { data, isLoading, loadMore, setQuery, hasMore, error } = useInfiniteAPI({
        defaultQuery: query,
        getAPI: ({ page, query }) => `${API_BASE_URL}/unsplash?query=${query}&page=${page}&per_page=50`,
        getSize: (res) => Math.ceil(res.total / 50),
    });

    useEffect(() => {
        setQuery(query);
    }, [query]);

    const handleSelect = async (item, pos, element) => {
        const { urls, links } = item;
        const { width, height } = await getImageSize(urls.small);
        const imageSrc = { src: urls.regular };

        await axios.post(`${API_BASE_URL}/unsplash/download`, {
            downloadLink: links.download_location,
        });

        if (element && element.type === 'image' && element.contentEditable) {
            const crop = getCrop(element, { width, height });
            element.set(Object.assign(imageSrc, crop));
            return;
        }

        const x = (pos?.x || store.width / 2) - width / 2;
        const y = (pos?.y || store.height / 2) - height / 2;

        store.history.transaction(async () => {
            const img = store.activePage?.addElement({ type: 'image', width, height, x, y });
            if (isAlive(img)) {
                await img.set(imageSrc);
            }
        });
    };

    const images = data ? data.flat() : [];

    return (
        <PhotosContainer>
            <ImagesGrid
                shadowEnabled={false}
                images={images}
                getPreview={(item) => item.urls?.thumb || ''}
                isLoading={isLoading}
                onSelect={handleSelect}
                rowsNumber={2}
                loadMore={hasMore && loadMore}
                error={error}
                getCredit={(item) => (
                    <span>
            Photo by <a href={`https://unsplash.com/@${item.user.username}?utm_source=raeditor&utm_medium=referral`} target="_blank">{item.user.name}</a> on <a href="https://unsplash.com/?utm_source=raeditor&utm_medium=referral" target="_blank">Unsplash</a>
          </span>
                )}
            />
        </PhotosContainer>
    );
});

export const PhotosPanel = ({ store }) => {
    const requestTimeout = useRef();
    const [query, setQuery] = useState('');
    const [delayedQuery, setDelayedQuery] = useState(query);

    const handleImageSearch = (event)=>{

        const newValue = event.target.value;
        if (newValue.trim() !== '') { // Check if the input is not just whitespace
            setQuery(newValue);

        } else {
            setQuery(''); // Reset search state
        }
    }

    useEffect(() => {
        requestTimeout.current = setTimeout(() => {
            setDelayedQuery(query);
        }, 500);
        return () => clearTimeout(requestTimeout.current);
    }, [query]);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <InputGroup
                leftIcon="search"
                placeholder={t('sidePanel.searchPlaceholder')}
                // onChange={(e) => setQuery(e.target.value)}
                onChange={handleImageSearch}
                value={query}
                type="search"
                style={{ marginBottom: '20px' }}
            />
            <p style={{ textAlign: 'center' }}>
                Photos by <a href="https://unsplash.com/" target="_blank">Unsplash</a>
            </p>
            <UnsplashPanel query={delayedQuery} store={store} />
        </div>
    );
};

export const PhotosSection = {
    name: 'photos',
    Tab: observer((props) => (
        <SectionTab name={t('sidePanel.photos')} {...props}>
            <FaPhotoVideo className='w-5 h-5' />
        </SectionTab>
    )),
    Panel: PhotosPanel,
};
